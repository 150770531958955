.bol-table {
  $border-radius--td: $border-radius;
  $spacing-x--td: 15px;
  $spacing-y--td: 10px;
  $padding--td: $spacing-y--td $spacing-x--td;

  background-color: $color--base;
  border-collapse: separate;
  border-radius: $border-radius--td; 
  border-color: $color--base;
  border-spacing: 0;
  border: $border;
  margin-bottom: $margin-bottom--section;
  width: 100%;

  @media #{$medium-up} {
    margin-bottom: $margin-bottom--section--md;
  }

  @media #{$large-up} {
    margin-bottom: $margin-bottom--section--lg;
  }

  &__caption {
    @include ada-hidden;
  }

  &__thead {
    &--shaded {
      .bol-table__th {
        background-color: $color-gray-s-10;
      }

      .bol-table__tr {
        &:first-child {
          .bol-table__th:first-child {
            border-top-left-radius: $border-radius;
          }
          .bol-table__th:last-child {
            border-top-right-radius: $border-radius;
          }
        }

        &:last-child {
          border-bottom: $border;
        }
      }
    }
  }

  &__tbody {
    .bol-table__tr {
      &:first-child {
        >.bol-table__td {
          border-top: none;
        }
      }
    }
  }

  &__tr {
    background-color: transparent;
    border: none;
    overflow: hidden;

    &--subheading {
      &-color {
        background-color: $color-white !important;
        border: none !important;
        border-radius: 0 !important;
        margin: 0 (-$spacing-x) $spacing-y;

        .bol-table__td {
          &:first-child {
            background-color: transparent !important;

            &::before {
              display: block;
              height: 100%;
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              top: 0;
              box-shadow: $box-shadow, 5px 0 #{$color-black} inset;
            }
          }
        }
      }

      @each $color-name, $color-value in $bol-colors {
        &-#{$color-name} {
          @extend .bol-table__tr--subheading-color;

          .bol-table__td {
            &:first-child {
              &::before {
                box-shadow: $box-shadow, 5px 0 #{$color-value} inset;
              }
            }
          }
        }
      }
    }
  }

  &__th {
    background-color: transparent;
    font-weight: bold;
    text-align: left;
    padding: $padding--td;
    border-bottom: $border;
  }

  &__td {
    background-color: transparent;
    border-top: $border;
    font-weight: normal;
    position: relative;
    text-align: left;
    padding: $padding--td;
    width: auto;

    &:empty {
      padding: 0;
    }







    &--separated {
      position: relative;
      padding-top: $spacing-y--td !important;

      &::before {
        content: '';
        position: absolute;
        background-color: $color--border;
        height: 1px;
        width: calc(100% - 2 * #{$spacing-x--td});
        margin-left: $spacing-x--td;
        top: 0;
        bottom: 0;
        left: 0;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      @media #{$medium-up} {
        padding-top: 0;

        &::before {
          display: none;
        }
      }



















    }

    &--divided {
      position: relative;
      padding-top: $spacing-y--td !important;

      &::before {
        content: '';
        position: absolute;
        background-color: $color--border;
        height: 1px;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      @media #{$medium-up} {
        padding-top: 0;

        &::before {
          display: none;
        }
      }
    }




    &--qdia {
      padding-left: $spacing-x--td + $width--dot + $spacing-x--td;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: $spacing-x--td + ($width--dot / 2);
        transform: translateX(-50%) translateY(-50%);
        background-color: bol-color(color-1);
        border-radius: 50%;
        width: $width--dot;
        height: $width--dot;
      }
    }

    &--overall {
      padding-left: $spacing-x--td + $width--dot + $spacing-x--td;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: $spacing-x--td + ($width--dot / 2);
        transform: translateX(-50%) translateY(-50%);
        background-color: bol-color(color-2);
        border-radius: 50%;
        width: $width--dot;
        height: $width--dot;
      }
    }

    @at-root .#{$site-state-component} {
      .bol-table__td--qdia.bol-table__td--overall {
        padding-left: $spacing-x--td + $width--dot + ($spacing-x--td /2) + $width--dot + $spacing-x--td;

        &::before {
          left: $spacing-x--td + ($width--dot / 2);
        }

        &::after {
          left: $spacing-x--td + $width--dot + ($spacing-x--td /2) + ($width--dot / 2);
        }
      }
    }







  }



  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    &--cfg-a {


      .bol-table__key {
        @include make-flex-section(#{$flex-basis--smaller});
      }

      .bol-table__value {
        @include make-flex-section(#{$flex-basis--larger});
        text-align: left;
      }
    }

    &--cfg-b {


      .bol-table__key {
        @include make-flex-section(#{$flex-basis--half});
      }

      .bol-table__value {
        @include make-flex-section(#{$flex-basis--smaller-half});
      }
    }

    &--cfg-c {

      display: block;
      flex-direction: column;

      .bol-table__key {
        flex-basis: 100%;
        margin-right: 0;
      }

      .bol-table__value {
        flex-basis: 100%;
        text-align: left;
      }
    }

    &--cfg-d {


      .bol-table__key {
        @include make-flex-section(#{$flex-basis--larger});
      }

      .bol-table__value {
        @include make-flex-section(#{$flex-basis--smaller});
        text-align: left;
      }
    }

    &--cfg-e {


      .bol-table__key {
        @include make-flex-section(#{$flex-basis--larger});
      }

      .bol-table__value {
        @include make-flex-section(#{$flex-basis--smaller});
      }
    }





    &--cfg-h {

      @media #{$medium-up} {
        display: block;
        flex-direction: column;

        .bol-table__key {
          flex-basis: 100%;
          margin-right: 0;
        }

        .bol-table__value {
          flex-basis: 100%;
          text-align: left;
        }
      }
    }
  }

  &__key {
    @include make-flex-section();
    margin-right: $flex-section--margin;

    @media #{$medium-up} {
      display: none;
    }
  }

  &__value {
    @include make-flex-section();
    text-align: right;

    @media #{$medium-up} {
      display: block;
      text-align: left;
    }




  }

  &--hover {

    .bol-table__tbody .bol-table__tr {

      &:hover,
      &:focus {
        background-color: $color--hover;
        border: $border--hover;


        .bol-table__td {
          background-color: inherit;
        }
      }
    }
  }

  &--card,
  &--to-card {
    background-color: transparent;
    border-collapse: collapse;
    border: none;
    table-layout: auto; 

    @media #{$small-only} {
      display: block;
      .bol-table {
        &__td {
          padding-top: 0;

          &:first-child {
            padding-top: $spacing-y--td;
          }
        }
      }
    }

    .bol-table {
      &__thead {
        display: none;
      }

      &__tbody {
        display: block;
      }

      &__tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: $color--base;
        border: $border;
        border-radius: $border-radius--td;
        margin-bottom: $margin-bottom--section;
      }

      &__th {
        display: none;
      }

      &__td {
        display: block;
        border: none;


        width: 100%;




      }

      &__tfoot {
        display: block;
      }
    }
  }

  &--card {

    .bol-table {
      &__tr {
        &--primary {
          @include render-color-tab($color--primary);

          >.bol-table__td:first-child {
            @media #{$medium-up} {
              @include render-color-tab($color--primary);
            }
          }
        }

        &--positive {
          @include render-color-tab($color--positive);

          >.bol-table__td:first-child {
            @media #{$medium-up} {
              @include render-color-tab($color--positive);
            }
          }
        }

        &--negative {
          @include render-color-tab($color--negative);

          >.bol-table__td:first-child {
            @media #{$medium-up} {
              @include render-color-tab($color--negative);
            }
          }
        }
      }
    }

    @media #{$medium-up} {
      border-collapse: separate;
      border-spacing: 0 10px;
      border: none;
      margin-top: -10px; 

      .bol-table {
        &__tbody {
          display: table-row-group;

          .bol-table__tr {
            &:first-child {
              .bol-table__td {
                border-top: $border;
              }
            }
          }
        }

        &__tr {
          display: table-row;
          border: none;
          border-radius: 0;
          margin-bottom: 0;
        }

        &__td {
          display: table-cell;
          border-top: $border;
          border-bottom: $border;

          width: auto;

          &:first-child {
            border-left: $border;
            border-top-left-radius: $border-radius--td;
            border-bottom-left-radius: $border-radius--td;
          }

          &:last-child {
            border-right: $border;
            border-top-right-radius: $border-radius--td;
            border-bottom-right-radius: $border-radius--td;
          }
        }

        &__tfoot {
          display: table-footer-group;
        }



        &__key {
          display: block;
          flex-grow: 0;
          flex-shrink: 1;
        }

        &__value {
          display: block;
          text-align: left;
        }
      }
    }
  }

  &--to-card {


    @media #{$medium-up} {
      background-color: $color--base;
      border-collapse: separate;
      border: $border;

      .bol-table {
        &__thead {
          display: table-header-group;
        }

        &__tbody {
          display: table-row-group;
        }

        &__tr {
          display: table-row;
          background-color: transparent;
          border: none;
        }

        &__th {
          display: table-cell;
          border-bottom: $border;
        }

        &__td {
          display: table-cell;
          border-top: $border;

          width: auto;
        }

        &__tfoot {
          display: table-footer-group;
        }
      }
    }
  }

  &--to-list {
    border: none;

    @media #{$small-only} {
      display: block;

      .bol-table {
        &__td {
          padding-top: 0;

          &:first-child {
            padding-top: $spacing-y--td;
          }
        }
      }
    }

    .bol-table {
      &__thead {
        display: none;
      }

      &__tbody {
        display: block;
      }

      &__tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: $border;
        border-top: none;

        &:first-child {
          border-top: $border;
          border-top-left-radius: $border-radius--td;
          border-top-right-radius: $border-radius--td;
        }

        &:last-child {
          border-bottom-left-radius: $border-radius--td;
          border-bottom-right-radius: $border-radius--td;
        }
      }

      &__td {
        display: block;
        border: none;


        width: 100%;




      }

      &__tfoot {
        display: block;

        .bol-table__tr {
          &:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    @media #{$medium-up} {
      border: $border;

      .bol-table {
        &__thead {
          display: table-header-group;
        }

        &__tbody {
          display: table-row-group;
        }

        &__tr {
          display: table-row;
          border: none;
        }

        &__th {
          display: table-cell;
        }

        &__td {
          display: table-cell;
          background-color: transparent;
          border-top: $border;

          width: auto;
        }

        &__tfoot {
          display: table-footer-group;
        }
      }
    }
  }

  &--heading {



    @media #{$small-only} {
      .bol-table {
        &__thead {
          display: block;

          .bol-table__tr:not(:first-child) {
            display: none;
          }

          .bol-table__th:not(:first-child) {
            display: none;
          }

          .bol-table__tr {
            border-bottom: none;

            &:last-child {
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
            }
          }

          .bol-table__th {
            border-bottom: none;
          }
        }

        &__tbody {
          .bol-table__tr {
            &:first-child {
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }
          }
        }
      }
    }
  }

  &--total {

    &.bol-table--to-list {
      .bol-table__tbody {
        .bol-table__tr {
          &:last-child {
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  &--bordered {
    .bol-table__th,
    .bol-table__td {
      border-left: $border;
    }

    .bol-table__thead,
    .bol-table__tbody,
    .bol-table__tfoot {
      .bol-table__th,
      .bol-table__td {
        &:first-child {
          border-left: none;
        }
      }
    }

    &.bol-table--to-card,
    &.bol-table--to-list {
      .bol-table__thead,
      .bol-table__tbody,
      .bol-table__tfoot {
        @media #{$small-only} {
          .bol-table__th,
          .bol-table__td {
            border-left: none;
          }
        }
      }
    }

    &.bol-table--card {
      .bol-table__thead,
      .bol-table__tbody,
      .bol-table__tfoot {
        .bol-table__td {
          border-left: none;
        }

          @media #{$medium-up} {
          .bol-table__td {
            border-left: $border;
          }
        }
      }
    }
  }
}
