




@mixin nest-row-col-for-card($padding, $margin-bottom) {
  @include nest-row-col-in-parent-without-padding($padding: $padding, $margin-bottom: $margin-bottom);

    &__body {
    @include nest-row-col-in-parent-with-padding($padding: $padding, $margin-bottom: $margin-bottom);

    > #{$row-nested-modifier-selector} {
      > .column {
        @include nest-row-col-in-parent-with-padding($padding: $padding, $margin-bottom: $margin-bottom);
      }
    }
  }
}

@mixin render-nest-row-col-for-card() {
  @each $breakpoint-name, $breakpoint-query in $breakpoint-list {
    @if $breakpoint-name == 'small-up' {
      @include nest-row-col-for-card($padding: $spacing-x--card, $margin-bottom: $spacing-y--card);
    }
    @else if $breakpoint-name == 'medium-up' {
      @media #{$breakpoint-query} {
        @include nest-row-col-for-card($padding: $spacing-x--card--md, $margin-bottom: $spacing-y--card--md);
      }
    }
    @else if $breakpoint-name == 'large-up' {
      @media #{$breakpoint-query} {
        @include nest-row-col-for-card($padding: $spacing-x--card--lg, $margin-bottom: $spacing-y--card--lg);
      }
    }
    @else if $breakpoint-name == 'small-only' {
      @media #{$breakpoint-query} {
        &__body {
          > #{$row-nested-modifier-selector} {
            > .column.small-12 {
              padding-left: 0 !important;
              padding-right: 0 !important;
              margin-bottom: $spacing-y--card;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    @else if $breakpoint-name == 'medium-only' {
      @media #{$breakpoint-query} {
        &__body {
          > #{$row-nested-modifier-selector} {
            > .column.medium-12 {
              padding-left: 0 !important;
              padding-right: 0 !important;
              margin-bottom: $spacing-y--card--md;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}



