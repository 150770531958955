.bol-page-title {
  font-size: 36px; 
  line-height: 44px; 
  margin: $spacing-y 0;

  @media #{$medium-up} {
    margin: $spacing-y--md 0;
  }

  @media #{$large-up} {
    margin: $spacing-y--lg 0;
  }
}
