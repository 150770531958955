




$offset-spa-close-x: 24px;
$offset-scrollbar: 9px;



.bol-container-wrapper .spa-ui-layer, [data-sparta-container] .spa-ui-layer {
  &-close {
    z-index: 1; 
  }

  .spa-body {
    color: $color--body;
    font-family: $font--modal;
    font-weight: $font-weight--modal;
    font-size: $font-size--modal;
    line-height: $line-height--modal;

    .spa-ui-layer-title {
      color: $color--heading;
      font-family: $font--modal--heading;
      font-weight: $font-weight--modal--heading;
      font-size: $font-size--modal--heading;
      line-height: $line-height--modal--heading;
      margin-top: 0;
      margin-bottom: 16px;
    }

    [data-component="module"] > .bol-component,
    [data-options] {
      @for $i from 1 through 6 {
        > h#{$i}:not([class]):first-child {
          color: $color--heading !important;
          font-family: $font--modal--heading !important;
          font-weight: $font-weight--modal--heading !important;
          font-size: $font-size--modal--heading !important;
          line-height: $line-height--modal--heading !important;
          margin-top: 0 !important;
          margin-bottom: 16px !important;
        }
      }
    }

    p {
      font-size: inherit;
      line-height: inherit;
      margin-bottom: $margin-bottom--body;

      @media #{$medium-up} {
        margin-bottom: $margin-bottom--body--md;
      }
    }
  }
}



.bol-container-wrapper .spa-ui-modal, [data-sparta-container] .spa-ui-modal {
  &:before {
    background: $background-screen-overlay;
  }

  .spa-header {
    padding: 0;
  }

  .spa-ui-layer-close {
    top: 0;
  }

  .spa-content {
    border-radius: $border-radius;
    padding: $spacing-y--modal 0px 30px 0px; 
    margin: 0;

    @media #{$medium-up} {
      padding: $spacing-y--modal--md 0px 30px 0px;
    }

    @media #{$large-up} {
      padding: $spacing-y--modal--lg 0px 30px 0px;
    }
  }

  .spa-body {
    padding: 0;

    .spa-ui-layer-title {
      box-shadow: $box-shadow--header;
      padding: 0 #{$spacing-x--modal + $offset-spa-close-x} $spacing-y--modal $spacing-x--modal;

      @media #{$medium-up} {
        font-size: $font-size--modal--heading--md;
        line-height: $line-height--modal--heading--md;
        padding: 0 #{$spacing-x--modal--md + $offset-spa-close-x} $spacing-y--modal $spacing-x--modal--md;
      }

      @media #{$large-up} {
        padding: 0 #{$spacing-x--modal--lg + $offset-spa-close-x} $spacing-y--modal $spacing-x--modal--lg;
      }
    }

    [data-component="module"] > .bol-component,
    [data-options] {
      @for $i from 1 through 6 {
        > h#{$i}:not([class]):first-child {
          box-shadow: $box-shadow--header !important;
          padding: 0 #{$spacing-x--modal + $offset-spa-close-x} $spacing-y--modal $spacing-x--modal !important;

          @media #{$medium-up} {
            font-size: $font-size--modal--heading--md !important;
            line-height: $line-height--modal--heading--md !important;
            padding: 0 #{$spacing-x--modal--md + $offset-spa-close-x} $spacing-y--modal $spacing-x--modal--md !important;
          }

          @media #{$large-up} {
            padding: 0 #{$spacing-x--modal--lg + $offset-spa-close-x} $spacing-y--modal $spacing-x--modal--lg !important;
          }
        }
      }
    }

    .#{$site-state-component} {
      font-size: $font-size--modal;
      line-height: $line-height--modal;
    }

    p {
      margin-right: 0;
    }
  }

  .content {
    margin: 0 ($spacing-x--modal - $offset-scrollbar) 0 $spacing-x--modal;
    padding-right: $offset-scrollbar;

    @media #{$medium-up} {
      margin: 0 ($spacing-x--modal--md - $offset-scrollbar) 0 $spacing-x--modal--md;
    }

    @media #{$large-up} {
      margin: 0 ($spacing-x--modal--lg - $offset-scrollbar) 0 $spacing-x--modal--lg;
    }
  }
}



.bol-container-wrapper .spa-ui-info, [data-sparta-container] .spa-ui-info {
  border-radius: 0;

  .spa-layer-content {
    border-radius: $border-radius;
    box-shadow: $box-shadow--dialog;
  }

  border-radius: $border-radius;
  border: none;
  box-shadow: $box-shadow--dialog;
  max-width: 267px;

  .spa-header {
    height: 0 !important;
  }

  .spa-content .content p {
    color: inherit !important;
  }

  .spa-body {
    padding: $spacing-y-top--tooltip $spacing-x--tooltip $spacing-y-bottom--tooltip $spacing-x--tooltip !important;
    margin-right: 0 !important;

    .spa-ui-layer-title {
      padding-right: $offset-spa-close-x;
      color: $color--heading !important;
      font-family: $font--tooltip--heading !important;
      font-weight: $font-weight--tooltip--heading !important;
      font-size: $font-size--tooltip--heading !important;
      line-height: $line-height--tooltip--heading !important;
    }

    [data-component="module"] > .bol-component,
    [data-options] {
      @for $i from 1 through 6 {
        > h#{$i}:not([class]):first-child {
          padding-right: $offset-spa-close-x !important;
          color: $color--heading !important;
          font-family: $font--tooltip--heading !important;
          font-weight: $font-weight--tooltip--heading !important;
          font-size: $font-size--tooltip--heading !important;
          line-height: $line-height--tooltip--heading !important;
        }
      }
    }

    .#{$site-state-component} {
      font-size: $font-size--tooltip;
      line-height: $line-height--tooltip;
    }
  }
}
