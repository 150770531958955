.bol-stepper {
  width: 100%;

  &__group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__minus,
  &__plus {
    margin-bottom: 0;

    &::before {
      display: inline-block;
      color: $color-royal-one;
      font-family: $roboto-bold-family, $roboto-font-fallbacks;
      font-weight: bold;
      font-size: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    background-color: $color--base;
    border: $border--input;
    border-radius: $border-radius--input;
    position: relative;
    padding: 0;
    width: 60px;
    height: auto;
    touch-action: manipulation; 

    &:hover {
      background-color: $color--secondary;
      border-color: $color--secondary;

      &::before {
        color: $color-on--secondary;
      }
    }

    &--disabled {
      border-color: $color--disabled;

            &::before {
        color: $color-on--disabled;
      }

      &:hover {
        background-color: $color--base;
        border-color: $color--disabled;

        &::before {
          color: $color-on--disabled;
        }
      }
    }
  }

  &__minus {
    top: 45%;

    &::before {
      content: "-";
    }
  }

  &__plus {
    margin-left: 6px;

    &::before {
      content: "+";
    }
  }

  &__hint {
    display: block;
    color: $color-gray;
    font-size: $font-size--xsmall;
    line-height: $line-height--medium;
    margin: 0;

    &:empty {
      display: none;
    }
  }

  input.bol-stepper__input { 

    display: inline-block;
    border: $border--input;
    border-radius: $border-radius--input !important;
    color: $color-on--base;
    position: relative;
    font-family: $font--body;
    font-weight: normal;
    font-size: 18px; 
    text-align: center;
    margin-left: 6px !important;
    padding: 8px 8px;
    max-width: 130px; 
    height: auto;

    &:-ms-input-placeholder {
      color: #666 !important; 
    }

    &:focus {
      background-color: $color--base;
      border: $border--hover;
    }

    &--error {
      border-color: $color--error;
    }
  }

  &__field {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0;
  }

  &--percent {

    input.bol-stepper__input {
      padding-right: 38px;
      text-align: right;
    }

    .bol-stepper__field {
      &::after {
        content: '%';
        display: inline-block;
        font-weight: normal;
        font-size: 18px;
        position: absolute;
        margin: auto;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }      
    }
  }

  &--dollar {

    input.bol-stepper__input {
      padding-left: 38px;
      text-align: right;
    }

    .bol-stepper__field {
      &::after {
        content: '$';
        display: inline-block;
        font-weight: normal;
        font-size: 18px;
        position: absolute;
        margin: auto;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }      
    }
  }
}
