@import '_mixins.scss';

.spa-btn-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: $margin-bottom--section;
  width: 100%;

  > .spa-btn {
    margin-right: 0;
    margin-bottom: $margin-bottom--section;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media #{$medium-up} {
    flex-wrap: nowrap;
    margin-bottom: $margin-bottom--section--md;
    padding: 0;
    width: 100%;

    > .spa-btn {
      margin-bottom: 0;
      margin-left: $spacing-x;
      width: auto;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media #{$large-up} {
    margin-bottom: $margin-bottom--section--lg;
  }

  &--cfg-a {
    > .spa-btn:first-child:nth-last-child(2),
    > .spa-btn:first-child:nth-last-child(2) ~ .spa-btn {
      flex: 1; 
      margin-bottom: 0;
    }

    > .spa-btn:first-child:nth-last-child(2) ~ .spa-btn {
      margin-left: $spacing-x;
    }

    @media #{$medium-up} {
      > .spa-btn {
        flex: 0 1 auto !important;
      }

      > .spa-btn:last-child {
        margin-left: auto !important;
      }
    }
  }

  &--cfg-b {
    justify-content: flex-end;
  }

  &--cfg-c {

    > .spa-btn {
      width: auto;
    }

    @media #{$medium-up} {
      justify-content: flex-end;
    }
  }

  &--segmented {
    @include render-segmented-button-group;
  }

  &--centered {
    justify-content: center;
    align-items: center;
  }

  &--stacked {
    flex-direction: column;

    > .spa-btn {
      margin-left: 0;
      margin-bottom: $margin-bottom--section;
    }

    > .spa-btn:last-child {
      margin-bottom: 0;
    }
  }
}
