
$font-size--heading--xlarge: 24px;
$line-height--heading--xlarge: 32px;


$font-size--heading--large: 20px;
$line-height--heading--large: 28px;


$font-size--heading: 18px;
$line-height--heading: 26px;


$font-size--subheading: 16px;
$line-height--subheading: 24px;


$font-size--xlarge: 24px;
$line-height--xlarge: 32px;


$font-size--large: 20px;
$line-height--large: 28px;


$font-size--medium: 16px;
$line-height--medium: 24px;


$font-size--small: 14px;
$line-height--small: 20px;


$font-size--xsmall: 12px;
$line-height--xsmall: 16px;


$font-size--body: $font-size--medium;
$line-height--body: $line-height--medium;


$font-size--disclaimer: $font-size--xsmall;
$line-height--disclaimer: $line-height--xsmall;


$font-size--modal--heading--md: $font-size--heading--large;
$line-height--modal--heading--md: $line-height--heading--large;


$font-size--modal--heading: $font-size--heading;
$line-height--modal--heading: $line-height--heading;


$font-size--modal: $font-size--small;
$line-height--modal: $line-height--small;


$font-size--tooltip--heading: $font-size--small;
$line-height--tooltip--heading: $line-height--small;


$font-size--tooltip: $font-size--small;
$line-height--tooltip: $line-height--small;


$font-size--btn: $font-size--medium; 
$line-height--btn: 1;
