


$color-brand-primary: #e31837;
$color-brand-secondary: #012169;
$color-brand-secondary-s-80: #344e87;
$color-brand-secondary-s-50: #8090b4;
$color-brand-secondary-s-20: #ccd3e1;
$color-brand-tertiary: #ffffff;


$color-black: #000000;
$color-black-alt: #333333;
$color-gray: #646464;
$color-gray-s-70: #919191;
$color-gray-s-40: #bababa;
$color-gray-s-20: #d5d5d5;
$color-gray-s-10: #ededed;
$color-gray-s-05: #f5f5f5;
$color-white: #ffffff;


$color-royal-one: #0053c2;
$color-royal-one-s80: #3375ce;
$color-royal-one-s50: #80a8e0;
$color-royal-one-s20: #ccdcf3;
$color-royal-two: #0073cf;
$color-royal-two-s80: #338fd9;
$color-royal-two-s50: #80b9e7;
$color-royal-two-s20: #cce3f5;
$color-royal-three: #009cde;
$color-royal-three-s80: #33b0e5;
$color-royal-three-s50: #80cdee;
$color-royal-three-s20: #ccebf8;
$color-royal-three-s10: #e0f0f6;
$color-royal-three-s05: #f2fafd;
$color-regal-one: #c41230;
$color-regal-one-s-80: #d14159;
$color-regal-one-s-50: #e28897;
$color-regal-one-s-20: #f3d0d6;
$color-regal-two: #780032;
$color-regal-two-s-80: #93335b;
$color-regal-two-s-50: #bb8099;
$color-regal-two-s-20: #e4ccd6;


$color-secondary-one-dark: #ea7000;
$color-secondary-one: #ea7600;
$color-secondary-one-s-80: #ee9133;
$color-secondary-one-s-50: #f4ba80;
$color-secondary-one-s-20: #fbe4cc;
$color-secondary-two-dark: #c78800;
$color-secondary-two: #f2a900;
$color-secondary-two-s-80: #f5ba33;
$color-secondary-two-s-50: #f8d480;
$color-secondary-two-s-20: #fceecc;
$color-secondary-three: #279f00;
$color-secondary-three-s-80: #52b233;
$color-secondary-three-s-50: #93cf80;
$color-secondary-three-s-20: #d4eccc;
$color-secondary-four-dark: #34a290;
$color-secondary-four: #40c1ac;
$color-secondary-four-s-80: #66cdbd;
$color-secondary-four-s-50: #9fe0d5;
$color-secondary-four-s-20: #d9f3ee;
$color-secondary-five: #006980;
$color-secondary-five-s-80: #338799;
$color-secondary-five-s-50: #80b4bf;
$color-secondary-five-s-20: #cce1e6;
$color-secondary-six: #c74e69;
$color-secondary-six-s-80: #d27187;
$color-secondary-six-s-50: #e3a6b4;
$color-secondary-six-s-20: #f4dce1;
$color-secondary-seven: #5f2167;
$color-secondary-seven-s-80: #784a77;
$color-secondary-seven-s-50: #aa8eaa;
$color-secondary-seven-s-20: #dacedc;
$color-secondary-eight: #9a7611;
$color-secondary-eight-s-80: #ae9141;
$color-secondary-eight-s-50: #ccba88;
$color-secondary-eight-s-20: #ebe4cf;


$color-secondary-positive: #007700;
$color-secondary-negative: #B41818;
$color-secondary-neutral: #50585F;




$color--headline: $color-black;
$color--heading: $color-black;
$color--body: $color-black;
$color--body--lighter: $color-gray; 
$color--body--lightest: $color-gray-s-70; 
$color--disclaimer: $color-gray;


$color--link: $color-royal-one;
$color--link-hover: $color-royal-two;
$color--link-focus: darken($color--link-hover, 10%); 


$color--border: $color-gray-s-20;
$color--border-hover: $color-royal-one;


$color--inactive: $color-gray;
$color--active: $color-royal-one;
$color--hover: $color-royal-three-s05;


$color--positive: $color-secondary-positive;
$color--negative: $color-secondary-negative;
$color--success: $color--positive;
$color--error: $color--negative;


$color--base: $color-white;
$color-on--base: $color-black;


$color--primary: $color-brand-secondary;
$color-on--primary: $color-white;


$color--secondary: $color-royal-one;
$color-on--secondary: $color-white;


$color--tertiary: $color-brand-tertiary;
$color-on--tertiary: $color-brand-secondary;


$color--disabled: $color-gray-s-10;
$color-on--disabled: $color-gray;


$color--todo: $color-secondary-one;
$color--education: $color-secondary-five;
$color--insight: $color-secondary-seven;



$brand-colors: (
  'base':       $color-brand-tertiary,  
  'primary':    $color-brand-secondary,
  'bofa':       $color-brand-primary
);

$contextual-colors: (
  'active':     $color--active,
  'positive':   $color--positive,
  'negative':   $color--negative
);



$bol-colors: (
  'color-0': #919191,
  'color-1': #012169,
  'color-2': #0073CF,
  'color-3': #D1C9C0,
  'color-4': #6B5E51,
  'color-5': #673BB7,
  'color-6': #AA0061,
  'color-7': #EA7600,
  'color-8': #FFCC00,
  'color-9': #512850,
  'color-10': #72531D,
  'color-11': #6CC24A,
  'color-12': #840C56,
  'color-13': #4A116F,
  'color-14': #E7A614,
  'color-15': #B94700,
  'color-16': #74381E,
  'color-17': #5C068B,
  'color-18': #512850,
  'color-19': #007749,
  'color-20': #164734,
  'color-21': #009BDE
);

@function bol-color($color) {
  @return map-get($bol-colors, $color);
}




$bol-doc-colors: (
  'color-1': #338799,
  'color-2': #EE9133,
  'color-3': #784A77,
  'color-4': #9A7611,
  'color-5': #66CDBD,
  'color-6': #D27187,
  'color-7': #646464,
  'color-8': #F5BA33,
  'color-9': #C74E69,
  'color-10': #006980
);

@function bol-doc-color($color) {
  @return map-get($bol-doc-colors, $color);
}


