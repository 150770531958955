.bol-badge {
  background-color: $color-royal-three; 
  color: $color-white;
  border-radius: $border-radius--badge;
  font-size: $font-size--xsmall;
  text-align: center;
  padding: $padding--badge;
  min-width: 80px;
  width: 80px;

  &--new {
    background-color: $color-royal-three;
    color: $color-white;
    width: 80px;
  }

  &--todo {
    background-color: $color--todo;
    color: $color-white;
    width: 80px;
  }

  &--education {
    background-color: $color--education;
    color: $color-white;
    width: 80px;
  }

  &--insight {
    background-color: $color--insight;
    color: $color-white;
    width: 80px;
  }

  &--featured {
    background-color: $color--education;
    color: $color-white;
    width: 110px;
  }

  &:empty {
    display: none;
  }
}
