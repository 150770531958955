.bol-carousel {
    &__item {
      display: none;  

      &:focus {
        outline:none; 
      }
      &--active {
      }

      &--fade { 
          animation-name: fade;  
          animation-duration: 1s;  
      }
       .bol-card {
        box-shadow: none;

                > .bol-card__body {
          padding: 0;
          &:focus {
            outline:none; 
          }
        }
        > .bol-card__footer {
          padding: 24px 0 0 0;
        }
      }
    }

      &__footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
     }

     &__dots {
      display: flex;

              &--active {
          background-color: $color-brand-secondary;
          padding: 0px;
          min-width: 10px;
          width: 10px;
          height: 10px;
          margin: 0 $spacing-x;
          border-radius: 50%;
        }
        &--inactive {
          background-color: $color-gray-s-70;
          padding: 0px;
          min-width: 10px;
          width: 10px;
          height: 10px;
          margin: 0 $spacing-x;
          border-radius: 50%;
        }
    }

    &__prev {
      position: relative;
      transition: 0.6s ease;  
      padding: 0 $spacing-x;
      height: 24px;
      width: 24px;
      opacity:1;
      &::after {
        content: '';
        position: absolute;
        margin: 0 10px;
        top: 50%;
        right: 0;
        bottom: auto;
        left: 0;
        width: 10px;
        height: 10px;
        border-right: 2px solid $color-brand-secondary;
        border-bottom: 2px solid $color-brand-secondary;
        transform: translateY(-50%) rotate(135deg);
      }
      &[aria-disabled="true"]{
        opacity: 0;
        cursor: pointer;
      }
    }
    &__next {
      position: relative;
      transition: 0.6s ease;  
      padding: 0 $spacing-x;
      height: 24px;
      width: 24px;
      opacity:1;
      &::after {
        content: '';
        position: absolute;
        margin: 0 10px;
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        width: 10px;
        height: 10px;
        border-left: 2px solid $color-brand-secondary;
        border-bottom: 2px solid $color-brand-secondary;
        transform: translateY(-50%) rotate(-135deg);
      }
      &[aria-disabled="true"]{
        opacity: 0;
        cursor: pointer;
      }
    }
  }

    @keyframes fade {  
      from {  
          opacity: .4  
      }  
      to {  
          opacity: 1  
      }  
  }
