@import '_mixins.scss';

.bol-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: $color--base;
  border: 1px solid transparent; 
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  width: 100%;
  @include push-down;

  &:empty {
    display: none;
  }

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: transparent; 
    align-items: baseline; 
    padding: $padding--card;
    width: 100%;

    + .bol-card__header,
    + .bol-card__body,
    + .bol-card__footer {
      padding-top: 0;

      @media #{$large-up} {
        margin-top: $margin-top--card--lg;
      }
    }

    &--primary {
      border-top: $border-radius solid $color--primary;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    @media #{$medium-up} {
      padding: $padding--card--md;
    }

    @media #{$large-up} {
      padding: $padding--card--lg;
    }
  }

  &__heading {


    font-family: $font--heading;
    font-weight: $font-weight--heading;
    font-size: $font-size--heading;
    line-height: $line-height--heading;
    margin: 0;
    word-break: break-word;
    overflow: hidden;

    @media #{$ie-only} {
      width: 100%;
      word-wrap: break-word;
    }

    > * {
      vertical-align: middle; 
    }

    &--large {
      font-size: $font-size--heading--large;
      line-height: $line-height--heading--large;
    }

    &--xlarge {
      font-size: $font-size--heading--xlarge;
      line-height: $line-height--heading--xlarge;
    }
  }

  &__subheading {
    font-family: $font--subheading;
    font-weight: $font-weight--subheading;
    font-size: $font-size--subheading;
    line-height: $line-height--subheading;

    &:empty {
      display: none;
    }
  }

  &__body {
    padding: $padding--card;
    width: 100%;

    + .bol-card__body,
    + .bol-card__footer {
      padding-top: 0;
    }

    @media #{$medium-up} {
      padding: $padding--card--md;
    }

    @media #{$large-up} {
      padding: $padding--card--lg;
    }
  }

  &__footer {
    padding: $padding--card;
    width: 100%;

        @media #{$medium-up} {
      padding: $padding--card--md;
    }

    @media #{$large-up} {
      padding: $padding--card--lg;
    }
  }

  &__body,
  &__footer {

    &--separated,
    &--divided {
      padding-top: $spacing-y--card !important;

      @media #{$medium-up} {
        padding-top: $spacing-y--card--md !important;
      }

      @media #{$large-up} {
        padding-top: $spacing-y--card--lg !important;
        margin-top: 0;
      }
    }

    &--separated {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-color: $color--border;
        height: 1px;
        width: calc(100% - 2 * #{$spacing-x--card});
        margin-left: $spacing-x--card;
        top: 0;
        bottom: 0;
        left: 0;

        @media #{$medium-up} {
          width: calc(100% - 2 * #{$spacing-x--card--md});
          margin-left: $spacing-x--card--md;
        }

        @media #{$large-up} {
          width: calc(100% - 2 * #{$spacing-x--card--lg});
          margin-left: $spacing-x--card--lg;
        }
      }      
    }

    &--divided {
      border-top: $border;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

    > .bol-card__key {
      &:only-child {

        flex-basis: auto;
        width: 100%;
      }
    }

    &--toggle {
      @include render-toggle;

      &::after {
        border-color: $color-gray;
      }

      &:hover,
      &:focus {
        text-decoration: none;

        &::after {
          border-color: $color--body;
        }
      }
    }

    &--action {
      @include render-action;

      &:hover {
        text-decoration: none;
      }
    }

    &--cfg-a {

      .bol-card__value {
        text-align: left;
      }
    }

    &--cfg-b {

      .bol-card__key {
        @include make-flex-section(#{$flex-basis--half});
      }

      .bol-card__value {
        @include make-flex-section(#{$flex-basis--smaller-half});
      }
    }

    &--cfg-c {

      flex-direction: column;

      .bol-card__key {
        @include make-flex-section();
      }

      .bol-card__value {
        @include make-flex-section();
        text-align: left;
        margin-left: 0;
      }
    }

    &--cfg-d {

      .bol-card__key {
        @include make-flex-section(#{$flex-basis--smaller});
      }

      .bol-card__value {
        @include make-flex-section(#{$flex-basis--larger});
        text-align: left;
      }
    }
  }

  &__key {
    @include make-flex-section(#{$flex-basis--larger});
  }

  &__value {
    @include make-flex-section(#{$flex-basis--smaller});
    margin-left: $flex-section--margin;
    text-align: right;
  }

  &__perforation {
    text-align: center;
    display: block;
    position: relative;
    background: repeating-linear-gradient(to right,
        $color--border,
        $color--border 5px,
        transparent 5px,
        transparent 8px);
    height: 1px;
    margin: 12px 0;

    .bol-card__label {
      background: white;
      font-weight: bold;
      padding: 0 15px;
      position: relative;
      top: -12.5px;
    }
  }

  &__timepoint {
    padding-left: 20px !important;
    border-left: $border;
    position: relative;

    &:first-of-type {
      padding-top: 0 !important;
      border-image: linear-gradient(transparent 0, transparent 50%, $color--border 50%) 1;
      border-top-left-radius: $border-radius;
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
    }

    &:last-of-type {
      padding-bottom: 0 !important;
      border-image: linear-gradient($color--border 0, $color--border 50%, transparent 50%) 1;
      border-bottom-left-radius: $border-radius;
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
    }

    &:only-of-type {
      border: none;
    }

    &::before {
      content: '';
      height: 11px;
      width: 11px;
      background-color: $color--border;
      border-radius: 50%;
      position: absolute;
      left: -6px;
      top: calc(50% - 6px);
    }

    &:hover {
      background-color: $color--hover;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

    &--transparent {
    background-color: transparent;
    box-shadow: none;
  }

  &--bordered {
    border: $border;
    box-shadow: none;
  }

  &--horizontal {
    @media #{$medium-up} {
      flex-direction: row;
      flex-wrap: wrap;

        .bol-card__body {
        @include make-flex-section(#{$flex-basis--larger});
      }

        .bol-card__footer {
        @include make-flex-section(#{$flex-basis--smaller});
        margin-left: $flex-section--margin;
        text-align: right;
      }
    }  
  }

  > .bol-badge {
    margin: $padding--card;
    margin-bottom: 8px;
    margin-right: auto;

    &:not(:empty) {
      + .bol-card__header,
      + .bol-card__body,
      + .bol-card__footer {
        padding-top: 0;
      }
    }

        @media #{$medium-up} {
      margin: $padding--card--md;
      margin-bottom: 8px;
      margin-right: auto;
    }

    @media #{$large-up} {
      margin: $padding--card--lg;
      margin-bottom: 8px;
      margin-right: auto;
    }
  }

    .bol-card {
    box-shadow: none;
  }

  @include render-nest-row-col-for-card;
}
