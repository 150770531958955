



$bol-icon-sizes: (
  'micro': 12, 
  'xs':    16, 
  'xsp':   20,
  'sm':    24, 
  'smp':   32,
  'md':    40, 
  'lg':    48, 
  'xl':    56, 
  'mega':  80  
);

@function get-icon-size($name) {
  @return map-get($bol-icon-sizes, $name) * 1px;
}

.bol-icon {
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: middle;

  &[type='button'],
  &[role='button'] {

    background-color: transparent;
    appearance: none;
    border: none;
    cursor: pointer;
    vertical-align: middle;

    padding: 0; 
    overflow: hidden;
    margin: 0; 
    min-width: 24px; 

    &:focus {
      outline: $outline;
    }
  }

  @each $name, $size in $bol-icon-sizes{
    &.bol-icon-#{$name},
    &.bol-icon-#{$size} {
      width: $size * 1px;
      height: $size * 1px;
    }
  }

  &.bol-icon-alert {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTIxNjk7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEyLDIuNTIsMi41MiwyMS40OEgyMS40OFptMCwyLjIzNkwxOS44NjIsMjAuNDhINC4xMzhaIi8+PGVsbGlwc2UgY2xhc3M9ImNscy0xIiBjeD0iMTEuOTYxIiBjeT0iMTguMjI0IiByeD0iMC42MjEiIHJ5PSIwLjY1MyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMi4zMTkgMTYuNzE4IDEyLjQ2NiAxMS42NjUgMTEuNDU2IDExLjY2NSAxMS42MDMgMTYuNzE4IDEyLjMxOSAxNi43MTgiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNlMzE4Mzc7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEyLDIuNTIsMi41MiwyMS40OEgyMS40OFptMCwyLjIzNkwxOS44NjIsMjAuNDhINC4xMzhaIi8+PGVsbGlwc2UgY2xhc3M9ImNscy0xIiBjeD0iMTEuOTYxIiBjeT0iMTguMjI0IiByeD0iMC42MjEiIHJ5PSIwLjY1MyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMi4zMTkgMTYuNzE4IDEyLjQ2NiAxMS42NjUgMTEuNDU2IDExLjY2NSAxMS42MDMgMTYuNzE4IDEyLjMxOSAxNi43MTgiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGQ9Ik0xMiwyLjUyLDIuNTIsMjEuNDhIMjEuNDhabTAsMi4yMzZMMTkuODYyLDIwLjQ4SDQuMTM4WiIvPjxlbGxpcHNlIGN4PSIxMS45NjEiIGN5PSIxOC4yMjQiIHJ4PSIwLjYyMSIgcnk9IjAuNjUzIi8+PHBvbHlnb24gcG9pbnRzPSIxMi4zMTkgMTYuNzE4IDEyLjQ2NiAxMS42NjUgMTEuNDU2IDExLjY2NSAxMS42MDMgMTYuNzE4IDEyLjMxOSAxNi43MTgiLz48cmVjdCBjbGFzcz0iY2xzLTEiLz48L3N2Zz4=');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEyLDIuNTIsMi41MiwyMS40OEgyMS40OFptMCwyLjIzNkwxOS44NjIsMjAuNDhINC4xMzhaIi8+PGVsbGlwc2UgY2xhc3M9ImNscy0xIiBjeD0iMTEuOTYxIiBjeT0iMTguMjI0IiByeD0iMC42MjEiIHJ5PSIwLjY1MyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMi4zMTkgMTYuNzE4IDEyLjQ2NiAxMS42NjUgMTEuNDU2IDExLjY2NSAxMS42MDMgMTYuNzE4IDEyLjMxOSAxNi43MTgiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
  }

  &.bol-icon-asc {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTIxNjk7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIyMCAxMS4xNjIgMTIgMiA0IDExLjE2MiA0Ljc1MyAxMS44MiAxMS41IDQuMDkzIDExLjUgMjIgMTIuNSAyMiAxMi41IDQuMDkzIDE5LjI0NyAxMS44MiAyMCAxMS4xNjIiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNlMzE4Mzc7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIyMCAxMS4xNjIgMTIgMiA0IDExLjE2MiA0Ljc1MyAxMS44MiAxMS41IDQuMDkzIDExLjUgMjIgMTIuNSAyMiAxMi41IDQuMDkzIDE5LjI0NyAxMS44MiAyMCAxMS4xNjIiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwb2x5Z29uIHBvaW50cz0iMjAgMTEuMTYyIDEyIDIgNCAxMS4xNjIgNC43NTMgMTEuODIgMTEuNSA0LjA5MyAxMS41IDIyIDEyLjUgMjIgMTIuNSA0LjA5MyAxOS4yNDcgMTEuODIgMjAgMTEuMTYyIi8+PHJlY3QgY2xhc3M9ImNscy0xIi8+PC9zdmc+');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIyMCAxMS4xNjIgMTIgMiA0IDExLjE2MiA0Ljc1MyAxMS44MiAxMS41IDQuMDkzIDExLjUgMjIgMTIuNSAyMiAxMi41IDQuMDkzIDE5LjI0NyAxMS44MiAyMCAxMS4xNjIiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
    &--pos {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCSB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DTxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQkuc3Qwe2ZpbGw6IzAwNzcwMDt9DQkuc3Qxe2ZpbGw6bm9uZTt9DTwvc3R5bGU+DTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMjAsMTEuMiAxMiwyIDQsMTEuMiA0LjgsMTEuOCAxMS41LDQuMSAxMS41LDIyIDEyLjUsMjIgMTIuNSw0LjEgMTkuMiwxMS44ICIvPg08cmVjdCBjbGFzcz0ic3QxIi8+DTwvc3ZnPg0=');
    }
  }

  &.bol-icon-check {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxwYXRoIGZpbGw9IiMwMTIxNjkiIGQ9Ik0yMC4yMTYgNEw5Ljc4MSAxOC40NjZsLTYuMDUtNS45OTEtLjcwNC43MTFMOS45MDggMjAgMjEuMDI3IDQuNTg2IDIwLjIxNiA0eiIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiLz48L3N2Zz4=');
    }

    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxwYXRoIGZpbGw9IiNlMzE4MzciIGQ9Ik0yMC4yMTYgNEw5Ljc4MSAxOC40NjZsLTYuMDUtNS45OTEtLjcwNC43MTFMOS45MDggMjAgMjEuMDI3IDQuNTg2IDIwLjIxNiA0eiIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiLz48L3N2Zz4=');
    }

    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxwYXRoIGQ9Ik0yMC4yMTYgNEw5Ljc4MSAxOC40NjZsLTYuMDUtNS45OTEtLjcwNC43MTFMOS45MDggMjAgMjEuMDI3IDQuNTg2IDIwLjIxNiA0eiIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiLz48L3N2Zz4=');
    }

    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yMC4yMTYgNEw5Ljc4MSAxOC40NjZsLTYuMDUtNS45OTEtLjcwNC43MTFMOS45MDggMjAgMjEuMDI3IDQuNTg2IDIwLjIxNiA0eiIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiLz48L3N2Zz4=');
    }
  }

    &.bol-icon-cal {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiMwMTIxNjk7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJJQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHJlY3QgY2xhc3M9ImNscy0xIi8+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNMTUuMTMyLDkuMzE1aC0xdjIuMTMySDkuODY4VjkuMzE1aC0xdjIuMTMySDQuNjMydjFIOC44Njh2My4yMTFINC42MzJ2MUg4Ljg2OHYyLjEzMWgxVjE2LjY1OGg0LjI2NHYyLjEzMWgxVjE2LjY1OGg0LjIzNnYtMUgxNS4xMzJWMTIuNDQ3aDQuMjM2di0xSDE1LjEzMlptLTEsNi4zNDNIOS44NjhWMTIuNDQ3aDQuMjY0WiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTE3LjIzNiwzLjAyMVYxLjk0N2gtMVYzLjAyMWwtOC40NzIsMFYxLjk0N2gtMVYzLjAyNGwtNS4yNjQsMFYyMC44NjhoMjFWMy4wMTlabS0xMC40NzIsMXYxLjA4aDFWNC4wMjRsOC40NzIsMFY1LjEwNWgxVjQuMDJIMjEuNVY3LjIzNkgyLjVWNC4wMjZaTTIuNSwxOS44NjhWOC4yMzZoMTlWMTkuODY4WiIvPjwvZz48L3N2Zz4=')
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNlMzE4Mzc7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJJQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHJlY3QgY2xhc3M9ImNscy0xIi8+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNMTUuMTMyLDkuMzE1aC0xdjIuMTMySDkuODY4VjkuMzE1aC0xdjIuMTMySDQuNjMydjFIOC44Njh2My4yMTFINC42MzJ2MUg4Ljg2OHYyLjEzMWgxVjE2LjY1OGg0LjI2NHYyLjEzMWgxVjE2LjY1OGg0LjIzNnYtMUgxNS4xMzJWMTIuNDQ3aDQuMjM2di0xSDE1LjEzMlptLTEsNi4zNDNIOS44NjhWMTIuNDQ3aDQuMjY0WiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTE3LjIzNiwzLjAyMVYxLjk0N2gtMVYzLjAyMWwtOC40NzIsMFYxLjk0N2gtMVYzLjAyNGwtNS4yNjQsMFYyMC44NjhoMjFWMy4wMTlabS0xMC40NzIsMXYxLjA4aDFWNC4wMjRsOC40NzIsMFY1LjEwNWgxVjQuMDJIMjEuNVY3LjIzNkgyLjVWNC4wMjZaTTIuNSwxOS44NjhWOC4yMzZoMTlWMTkuODY4WiIvPjwvZz48L3N2Zz4=')
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJJQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHJlY3QgY2xhc3M9ImNscy0xIi8+PHBhdGggZD0iTTE1LjEzMiw5LjMxNWgtMXYyLjEzMkg5Ljg2OFY5LjMxNWgtMXYyLjEzMkg0LjYzMnYxSDguODY4djMuMjExSDQuNjMydjFIOC44Njh2Mi4xMzFoMVYxNi42NThoNC4yNjR2Mi4xMzFoMVYxNi42NThoNC4yMzZ2LTFIMTUuMTMyVjEyLjQ0N2g0LjIzNnYtMUgxNS4xMzJabS0xLDYuMzQzSDkuODY4VjEyLjQ0N2g0LjI2NFoiLz48cGF0aCBkPSJNMTcuMjM2LDMuMDIxVjEuOTQ3aC0xVjMuMDIxbC04LjQ3MiwwVjEuOTQ3aC0xVjMuMDI0bC01LjI2NCwwVjIwLjg2OGgyMVYzLjAxOVptLTEwLjQ3MiwxdjEuMDhoMVY0LjAyNGw4LjQ3MiwwVjUuMTA1aDFWNC4wMkgyMS41VjcuMjM2SDIuNVY0LjAyNlpNMi41LDE5Ljg2OFY4LjIzNmgxOVYxOS44NjhaIi8+PC9nPjwvc3ZnPg==')
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJJQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHJlY3QgY2xhc3M9ImNscy0xIi8+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNMTUuMTMyLDkuMzE1aC0xdjIuMTMySDkuODY4VjkuMzE1aC0xdjIuMTMySDQuNjMydjFIOC44Njh2My4yMTFINC42MzJ2MUg4Ljg2OHYyLjEzMWgxVjE2LjY1OGg0LjI2NHYyLjEzMWgxVjE2LjY1OGg0LjIzNnYtMUgxNS4xMzJWMTIuNDQ3aDQuMjM2di0xSDE1LjEzMlptLTEsNi4zNDNIOS44NjhWMTIuNDQ3aDQuMjY0WiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTE3LjIzNiwzLjAyMVYxLjk0N2gtMVYzLjAyMWwtOC40NzIsMFYxLjk0N2gtMVYzLjAyNGwtNS4yNjQsMFYyMC44NjhoMjFWMy4wMTlabS0xMC40NzIsMXYxLjA4aDFWNC4wMjRsOC40NzIsMFY1LjEwNWgxVjQuMDJIMjEuNVY3LjIzNkgyLjVWNC4wMjZaTTIuNSwxOS44NjhWOC4yMzZoMTlWMTkuODY4WiIvPjwvZz48L3N2Zz4=')
    }
    &--regal-2 {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiM3ODAwMzI7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJJQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHJlY3QgY2xhc3M9ImNscy0xIi8+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNMTUuMTMyLDkuMzE1aC0xdjIuMTMySDkuODY4VjkuMzE1aC0xdjIuMTMySDQuNjMydjFIOC44Njh2My4yMTFINC42MzJ2MUg4Ljg2OHYyLjEzMWgxVjE2LjY1OGg0LjI2NHYyLjEzMWgxVjE2LjY1OGg0LjIzNnYtMUgxNS4xMzJWMTIuNDQ3aDQuMjM2di0xSDE1LjEzMlptLTEsNi4zNDNIOS44NjhWMTIuNDQ3aDQuMjY0WiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTE3LjIzNiwzLjAyMVYxLjk0N2gtMVYzLjAyMWwtOC40NzIsMFYxLjk0N2gtMVYzLjAyNGwtNS4yNjQsMFYyMC44NjhoMjFWMy4wMTlabS0xMC40NzIsMXYxLjA4aDFWNC4wMjRsOC40NzIsMFY1LjEwNWgxVjQuMDJIMjEuNVY3LjIzNkgyLjVWNC4wMjZaTTIuNSwxOS44NjhWOC4yMzZoMTlWMTkuODY4WiIvPjwvZz48L3N2Zz4K');
    }
  }

  &.bol-icon-cards {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0ibGF5ZXJfMSIgZGF0YS1uYW1lPSJsYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTIxNjk7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTExLjA3MSwxMS4wNzFIMS41VjEuNWg5LjU3MVptLTguNTcxLTFoNy41NzFWMi41SDIuNVoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMS4wNzEsMjIuNUgxLjVWMTIuOTI5aDkuNTcxWk0yLjUsMjEuNWg3LjU3MVYxMy45MjlIMi41WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjUsMTEuMDcxSDEyLjkyOVYxLjVIMjIuNVptLTguNTcxLTFIMjEuNVYyLjVIMTMuOTI5WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjUsMjIuNUgxMi45MjlWMTIuOTI5SDIyLjVabS04LjU3MS0xSDIxLjVWMTMuOTI5SDEzLjkyOVoiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0ibGF5ZXJfMSIgZGF0YS1uYW1lPSJsYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNlMzE4Mzc7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTExLjA3MSwxMS4wNzFIMS41VjEuNWg5LjU3MVptLTguNTcxLTFoNy41NzFWMi41SDIuNVoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMS4wNzEsMjIuNUgxLjVWMTIuOTI5aDkuNTcxWk0yLjUsMjEuNWg3LjU3MVYxMy45MjlIMi41WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjUsMTEuMDcxSDEyLjkyOVYxLjVIMjIuNVptLTguNTcxLTFIMjEuNVYyLjVIMTMuOTI5WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjUsMjIuNUgxMi45MjlWMTIuOTI5SDIyLjVabS04LjU3MS0xSDIxLjVWMTMuOTI5SDEzLjkyOVoiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0ibGF5ZXJfMSIgZGF0YS1uYW1lPSJsYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGQ9Ik0xMS4wNzEsMTEuMDcxSDEuNVYxLjVoOS41NzFabS04LjU3MS0xaDcuNTcxVjIuNUgyLjVaIi8+PHBhdGggZD0iTTExLjA3MSwyMi41SDEuNVYxMi45MjloOS41NzFaTTIuNSwyMS41aDcuNTcxVjEzLjkyOUgyLjVaIi8+PHBhdGggZD0iTTIyLjUsMTEuMDcxSDEyLjkyOVYxLjVIMjIuNVptLTguNTcxLTFIMjEuNVYyLjVIMTMuOTI5WiIvPjxwYXRoIGQ9Ik0yMi41LDIyLjVIMTIuOTI5VjEyLjkyOUgyMi41Wm0tOC41NzEtMUgyMS41VjEzLjkyOUgxMy45MjlaIi8+PHJlY3QgY2xhc3M9ImNscy0xIi8+PC9zdmc+');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0ibGF5ZXJfMSIgZGF0YS1uYW1lPSJsYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTExLjA3MSwxMS4wNzFIMS41VjEuNWg5LjU3MVptLTguNTcxLTFoNy41NzFWMi41SDIuNVoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMS4wNzEsMjIuNUgxLjVWMTIuOTI5aDkuNTcxWk0yLjUsMjEuNWg3LjU3MVYxMy45MjlIMi41WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjUsMTEuMDcxSDEyLjkyOVYxLjVIMjIuNVptLTguNTcxLTFIMjEuNVYyLjVIMTMuOTI5WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjUsMjIuNUgxMi45MjlWMTIuOTI5SDIyLjVabS04LjU3MS0xSDIxLjVWMTMuOTI5SDEzLjkyOVoiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4=');
    }
    &--mdg {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0ibGF5ZXJfMSIgZGF0YS1uYW1lPSJsYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiM2NDY0NjQ7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTExLjA3MSwxMS4wNzFIMS41VjEuNWg5LjU3MVptLTguNTcxLTFoNy41NzFWMi41SDIuNVoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMS4wNzEsMjIuNUgxLjVWMTIuOTI5aDkuNTcxWk0yLjUsMjEuNWg3LjU3MVYxMy45MjlIMi41WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjUsMTEuMDcxSDEyLjkyOVYxLjVIMjIuNVptLTguNTcxLTFIMjEuNVYyLjVIMTMuOTI5WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjUsMjIuNUgxMi45MjlWMTIuOTI5SDIyLjVabS04LjU3MS0xSDIxLjVWMTMuOTI5SDEzLjkyOVoiLz48cmVjdCBjbGFzcz0iY2xzLTIiLz48L3N2Zz4K');
    }
  }

  &.bol-icon-doc {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGZpbGw9IiMwMTIxNjkiIGQ9Ik00LjMgMS41djIxSDE1bDQuNy00LjdWMS41SDQuM3ptMSAxaDEzLjR2MTQuNmgtNC40djQuNGgtOXYtMTl6TTE4IDE4LjFsLTIuNyAyLjd2LTIuN0gxOHoiLz48cGF0aCBmaWxsPSIjMDEyMTY5IiBkPSJNNy41IDExLjVoOC45djFINy41ek03LjUgNy4xaDguOXYxSDcuNXpNNy41IDE1LjloNS42djFINy41eiIvPjwvc3ZnPg==');
    }

    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGZpbGw9IiNFMzE4MzciIGQ9Ik00LjMgMS41djIxSDE1bDQuNy00LjdWMS41SDQuM3ptMSAxaDEzLjR2MTQuNmgtNC40djQuNGgtOXYtMTl6TTE4IDE4LjFsLTIuNyAyLjd2LTIuN0gxOHoiLz48cGF0aCBmaWxsPSIjRTMxODM3IiBkPSJNNy41IDExLjVoOC45djFINy41ek03LjUgNy4xaDguOXYxSDcuNXpNNy41IDE1LjloNS42djFINy41eiIvPjwvc3ZnPg==');
    }

    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGQ9Ik00LjMgMS41djIxSDE1bDQuNy00LjdWMS41SDQuM3ptMSAxaDEzLjR2MTQuNmgtNC40djQuNGgtOXYtMTl6TTE4IDE4LjFsLTIuNyAyLjd2LTIuN0gxOHoiLz48cGF0aCBkPSJNNy41IDExLjVoOC45djFINy41ek03LjUgNy4xaDguOXYxSDcuNXpNNy41IDE1LjloNS42djFINy41eiIvPjwvc3ZnPg==');
    }

    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGZpbGw9IiNGRkYiIGQ9Ik00LjMgMS41djIxSDE1bDQuNy00LjdWMS41SDQuM3ptMSAxaDEzLjR2MTQuNmgtNC40djQuNGgtOXYtMTl6TTE4IDE4LjFsLTIuNyAyLjd2LTIuN0gxOHoiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNNy41IDExLjVoOC45djFINy41ek03LjUgNy4xaDguOXYxSDcuNXpNNy41IDE1LjloNS42djFINy41eiIvPjwvc3ZnPg==');
    }
  }

    &.bol-icon-download {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6IzAxMjE2OX08L3N0eWxlPjwvZGVmcz48cGF0aCB0cmFuc2Zvcm09InJvdGF0ZSg5MCAxMiAxMikiIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX18yNF94XzI0X0JPWCIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxnIGlkPSJwcmVmaXhfX0lDT05fT1VUTElORSIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjAgOHYxMUg0VjhIM3YxMmgxOFY4aC0xeiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTYuODc5IDEwLjMwN2wtLjc1OC0uNjUtMy42MjEgNC4yMjRWNGgtMXY5Ljg4MUw3Ljg3OSA5LjY1N2wtLjc1OC42NUwxMiAxNmw0Ljg3OS01LjY5M3oiLz48L2c+PC9zdmc+');
    }
    &--roy {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6IzAwNTJjMn08L3N0eWxlPjwvZGVmcz48cGF0aCB0cmFuc2Zvcm09InJvdGF0ZSg5MCAxMiAxMikiIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX18yNF94XzI0X0JPWCIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxnIGlkPSJwcmVmaXhfX0lDT05fT1VUTElORSIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjAgOHYxMUg0VjhIM3YxMmgxOFY4aC0xeiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTYuODggMTAuMzFsLS43Ni0uNjUtMy42MiA0LjIyVjRoLTF2OS44OEw3Ljg4IDkuNjZsLS43Ni42NUwxMiAxNmw0Ljg4LTUuNjl6Ii8+PC9nPjwvc3ZnPg==');
    }

    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6I2UzMTgzN308L3N0eWxlPjwvZGVmcz48cGF0aCB0cmFuc2Zvcm09InJvdGF0ZSg5MCAxMiAxMikiIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX18yNF94XzI0X0JPWCIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxnIGlkPSJwcmVmaXhfX0lDT05fT1VUTElORSIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjAgOHYxMUg0VjhIM3YxMmgxOFY4aC0xeiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTYuODc5IDEwLjMwN2wtLjc1OC0uNjUtMy42MjEgNC4yMjRWNGgtMXY5Ljg4MUw3Ljg3OSA5LjY1N2wtLjc1OC42NUwxMiAxNmw0Ljg3OS01LjY5M3oiLz48L2c+PC9zdmc+');
    }

    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTI0IDB2MjRIMFYweiIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxnIGRhdGEtbmFtZT0iSUNPTiBPVVRMSU5FIj48cGF0aCBkPSJNMjAgOHYxMUg0VjhIM3YxMmgxOFY4aC0xeiIvPjxwYXRoIGQ9Ik0xNi44NzkgMTAuMzA3bC0uNzU4LS42NS0zLjYyMSA0LjIyNFY0aC0xdjkuODgxTDcuODc5IDkuNjU3bC0uNzU4LjY1TDEyIDE2bDQuODc5LTUuNjkzeiIvPjwvZz48L3N2Zz4=');
    }

    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6I2ZmZn08L3N0eWxlPjwvZGVmcz48cGF0aCB0cmFuc2Zvcm09InJvdGF0ZSg5MCAxMiAxMikiIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX18yNF94XzI0X0JPWCIgZGF0YS1uYW1lPSIyNCB4IDI0IEJPWCIvPjxnIGlkPSJwcmVmaXhfX0lDT05fT1VUTElORSIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjAgOHYxMUg0VjhIM3YxMmgxOFY4aC0xeiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTYuODc5IDEwLjMwN2wtLjc1OC0uNjUtMy42MjEgNC4yMjRWNGgtMXY5Ljg4MUw3Ljg3OSA5LjY1N2wtLjc1OC42NUwxMiAxNmw0Ljg3OS01LjY5M3oiLz48L2c+PC9zdmc+');
    }
  }

  &.bol-icon-desc {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTIxNjk7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxOS4yNDYgMTIuMTggMTIuNSAxOS45MDcgMTIuNSAyIDExLjUgMiAxMS41IDE5LjkwNyA0Ljc1NCAxMi4xOCA0IDEyLjgzNyAxMiAyMiAyMCAxMi44MzcgMTkuMjQ2IDEyLjE4Ii8+PHJlY3QgY2xhc3M9ImNscy0yIi8+PC9zdmc+');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNlMzE4Mzc7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxOS4yNDYgMTIuMTggMTIuNSAxOS45MDcgMTIuNSAyIDExLjUgMiAxMS41IDE5LjkwNyA0Ljc1NCAxMi4xOCA0IDEyLjgzNyAxMiAyMiAyMCAxMi44MzcgMTkuMjQ2IDEyLjE4Ii8+PHJlY3QgY2xhc3M9ImNscy0yIi8+PC9zdmc+');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwb2x5Z29uIHBvaW50cz0iMTkuMjQ2IDEyLjE4IDEyLjUgMTkuOTA3IDEyLjUgMiAxMS41IDIgMTEuNSAxOS45MDcgNC43NTQgMTIuMTggNCAxMi44MzcgMTIgMjIgMjAgMTIuODM3IDE5LjI0NiAxMi4xOCIvPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvc3ZnPg==');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxOS4yNDYgMTIuMTggMTIuNSAxOS45MDcgMTIuNSAyIDExLjUgMiAxMS41IDE5LjkwNyA0Ljc1NCAxMi4xOCA0IDEyLjgzNyAxMiAyMiAyMCAxMi44MzcgMTkuMjQ2IDEyLjE4Ii8+PHJlY3QgY2xhc3M9ImNscy0yIi8+PC9zdmc+');
    }
    &--neg {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCSB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DTxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQkuc3Qwe2ZpbGw6I0NDMDAwMDt9DQkuc3Qxe2ZpbGw6bm9uZTt9DTwvc3R5bGU+DTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTkuMiwxMi4yIDEyLjUsMTkuOSAxMi41LDIgMTEuNSwyIDExLjUsMTkuOSA0LjgsMTIuMiA0LDEyLjggMTIsMjIgMjAsMTIuOCAiLz4NPHJlY3QgY2xhc3M9InN0MSIvPg08L3N2Zz4N');
    }
  }

  &.bol-icon-info {

    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCSB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DTxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQkuc3Qwe2ZpbGw6I0ZGRkZGRjtzdHJva2U6IzAwNTNDMjt9DQkuc3Qxe2ZpbGw6IzAwNTJDMjt9DTwvc3R5bGU+DTxnIGlkPSJQYWdlLTEiPg0JPGcgaWQ9IkFydGJvYXJkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDcuMDAwMDAwLCAtNDIuMDAwMDAwKSI+DQkJPGcgaWQ9IndlYl94MkZfaWNvbl94MkZfYWxlcnRfeDJGX2luZm9feDJGX3JveWFsLW9uZV94MkZfZGVmYXVsdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDMuMDAwMDAwLCAzOC4wMDAwMDApIj4NCQkJPGNpcmNsZSBpZD0iYmFja2dyb3VuZCIgY2xhc3M9InN0MCIgY3g9IjE2IiBjeT0iMTYiIHI9IjcuNSIvPg0JCQk8cGF0aCBpZD0iaW5mbyIgY2xhc3M9InN0MSIgZD0iTTE2LjUsMTQuNnY1aC0xdi01SDE2LjV6IE0xNi41LDEyLjZ2MWgtMXYtMUgxNi41eiIvPg0JCTwvZz4NCTwvZz4NPC9nPg08L3N2Zz4N');

    &[type='button'],
    &[role='button'] {

      &:hover {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjMuMDAwMDAwLCAtMzguMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJ3ZWIvaWNvbi9hbGVydC9pbmZvL3JveWFsLW9uZS9mb2N1cyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIzLjAwMDAwMCwgMzguMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJob3ZlciIgZmlsbD0iI0NDRTNGNSIgY3g9IjEyIiBjeT0iMTIiIHI9IjEyIj48L2NpcmNsZT4KICAgICAgICAgICAgICAgIDxjaXJjbGUgaWQ9ImJhY2tncm91bmQiIHN0cm9rZT0iIzAwNTNDMiIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSIjRkZGRkZGIiBjeD0iMTIiIGN5PSIxMiIgcj0iNy41Ij48L2NpcmNsZT4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMi41LDEwLjU3MTMxNzYgTDEyLjUsMTUuNTcxMzE3NiBMMTEuNSwxNS41NzEzMTc2IEwxMS41LDEwLjU3MTMxNzYgTDEyLjUsMTAuNTcxMzE3NiBaIE0xMi41LDguNTcxMzE3NTUgTDEyLjUsOS41NzEzMTc1NSBMMTEuNSw5LjU3MTMxNzU1IEwxMS41LDguNTcxMzE3NTUgTDEyLjUsOC41NzEzMTc1NSBaIiBpZD0iaW5mbyIgZmlsbD0iIzAwNTJDMiI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
      }
      &:disabled { 
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCSB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DTxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQkuc3Qwe2ZpbGw6I0ZGRkZGRjtzdHJva2U6IzkxOTE5MTt9DQkuc3Qxe2ZpbGw6IzkxOTE5MTt9DTwvc3R5bGU+DTxnIGlkPSJQYWdlLTEiPg0JPGcgaWQ9IkFydGJvYXJkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjA3LjAwMDAwMCwgLTQyLjAwMDAwMCkiPg0JCTxnIGlkPSJ3ZWJfeDJGX2ljb25feDJGX2FsZXJ0X3gyRl9pbmZvX3gyRl9yb3lhbC1vbmVfeDJGX2luYWN0aXZlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMDMuMDAwMDAwLCAzOC4wMDAwMDApIj4NCQkJPGNpcmNsZSBpZD0iYmFja2dyb3VuZCIgY2xhc3M9InN0MCIgY3g9IjE2IiBjeT0iMTYiIHI9IjcuNSIvPg0JCQk8cGF0aCBpZD0iaW5mbyIgY2xhc3M9InN0MSIgZD0iTTE2LjUsMTQuNnY1aC0xdi01SDE2LjV6IE0xNi41LDEyLjZ2MWgtMXYtMUgxNi41eiIvPg0JCTwvZz4NCTwvZz4NPC9nPg08L3N2Zz4N');
      }
    }
    &--disabled { 
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCSB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DTxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQkuc3Qwe2ZpbGw6I0ZGRkZGRjtzdHJva2U6IzkxOTE5MTt9DQkuc3Qxe2ZpbGw6IzkxOTE5MTt9DTwvc3R5bGU+DTxnIGlkPSJQYWdlLTEiPg0JPGcgaWQ9IkFydGJvYXJkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjA3LjAwMDAwMCwgLTQyLjAwMDAwMCkiPg0JCTxnIGlkPSJ3ZWJfeDJGX2ljb25feDJGX2FsZXJ0X3gyRl9pbmZvX3gyRl9yb3lhbC1vbmVfeDJGX2luYWN0aXZlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMDMuMDAwMDAwLCAzOC4wMDAwMDApIj4NCQkJPGNpcmNsZSBpZD0iYmFja2dyb3VuZCIgY2xhc3M9InN0MCIgY3g9IjE2IiBjeT0iMTYiIHI9IjcuNSIvPg0JCQk8cGF0aCBpZD0iaW5mbyIgY2xhc3M9InN0MSIgZD0iTTE2LjUsMTQuNnY1aC0xdi01SDE2LjV6IE0xNi41LDEyLjZ2MWgtMXYtMUgxNi41eiIvPg0JCTwvZz4NCTwvZz4NPC9nPg08L3N2Zz4N');
    }    












  }

  &.bol-icon-help {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMntmaWxsOiMwMTIxNjl9PC9zdHlsZT48L2RlZnM+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgaWQ9Il8yNF9YXzI0X0JPWCIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxnIGlkPSJJQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTEyIDJhMTAgMTAgMCAxMDEwIDEwQTEwLjAxMSAxMC4wMTEgMCAwMDEyIDJ6bTAgMTlhOSA5IDAgMTE5LTkgOS4wMSA5LjAxIDAgMDEtOSA5eiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTExLjQyOSAxNi44NTdhLjcuNyAwIDAwLS43LjY4NC42OTQuNjk0IDAgMDAuNzE3LjY4NC42ODEuNjgxIDAgMDAuNy0uNjg0LjcwNi43MDYgMCAwMC0uNzE3LS42ODR6TTExLjkyNCA2YTkuODY1IDkuODY1IDAgMDAtMy4wODcuNTM0bC4zMjYuOTQ1QTkgOSAwIDAxMTEuOTI0IDdjMS43MDkgMCAyLjU3Ni41OTQgMi41NzYgMS43NjYgMCAuOTY5LS45MzUgMS44LTEuODM5IDIuNjA5LS45NDEuODM5LTEuOTEzIDEuNzA1LTEuOTEzIDIuODMyYTcuODkxIDcuODkxIDAgMDAuMTU5IDEuMzM1bC45ODUtLjE3M2E3LjI4MiA3LjI4MiAwIDAxLS4xNDQtMS4xNjJjMC0uNjc5Ljc2Ny0xLjM2MiAxLjU3OC0yLjA4NSAxLjAxOS0uOTA5IDIuMTc0LTEuOTM4IDIuMTc0LTMuMzU2QzE1LjUgNy45MzUgMTUuMTUxIDYgMTEuOTI0IDZ6Ii8+PC9nPjwvc3ZnPg==');
    }

    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMntmaWxsOiNlMzE4Mzd9PC9zdHlsZT48L2RlZnM+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgaWQ9Il8yNF9YXzI0X0JPWCIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxnIGlkPSJJQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTEyIDJhMTAgMTAgMCAxMDEwIDEwQTEwLjAxMSAxMC4wMTEgMCAwMDEyIDJ6bTAgMTlhOSA5IDAgMTE5LTkgOS4wMSA5LjAxIDAgMDEtOSA5eiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTExLjQyOSAxNi44NTdhLjcuNyAwIDAwLS43LjY4NC42OTQuNjk0IDAgMDAuNzE3LjY4NC42ODEuNjgxIDAgMDAuNy0uNjg0LjcwNi43MDYgMCAwMC0uNzE3LS42ODR6TTExLjkyNCA2YTkuODY1IDkuODY1IDAgMDAtMy4wODcuNTM0bC4zMjYuOTQ1QTkgOSAwIDAxMTEuOTI0IDdjMS43MDkgMCAyLjU3Ni41OTQgMi41NzYgMS43NjYgMCAuOTY5LS45MzUgMS44LTEuODM5IDIuNjA5LS45NDEuODM5LTEuOTEzIDEuNzA1LTEuOTEzIDIuODMyYTcuODkxIDcuODkxIDAgMDAuMTU5IDEuMzM1bC45ODUtLjE3M2E3LjI4MiA3LjI4MiAwIDAxLS4xNDQtMS4xNjJjMC0uNjc5Ljc2Ny0xLjM2MiAxLjU3OC0yLjA4NSAxLjAxOS0uOTA5IDIuMTc0LTEuOTM4IDIuMTc0LTMuMzU2QzE1LjUgNy45MzUgMTUuMTUxIDYgMTEuOTI0IDZ6Ii8+PC9nPjwvc3ZnPg==');
    }

    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxnIGRhdGEtbmFtZT0iSUNPTiBPVVRMSU5FUyI+PHBhdGggZD0iTTEyIDJhMTAgMTAgMCAxMDEwIDEwQTEwLjAxMSAxMC4wMTEgMCAwMDEyIDJ6bTAgMTlhOSA5IDAgMTE5LTkgOS4wMSA5LjAxIDAgMDEtOSA5eiIvPjxwYXRoIGQ9Ik0xMS40MjkgMTYuODU3YS43LjcgMCAwMC0uNy42ODQuNjk0LjY5NCAwIDAwLjcxNy42ODQuNjgxLjY4MSAwIDAwLjctLjY4NC43MDYuNzA2IDAgMDAtLjcxNy0uNjg0ek0xMS45MjQgNmE5Ljg2NSA5Ljg2NSAwIDAwLTMuMDg3LjUzNGwuMzI2Ljk0NUE5IDkgMCAwMTExLjkyNCA3YzEuNzA5IDAgMi41NzYuNTk0IDIuNTc2IDEuNzY2IDAgLjk2OS0uOTM1IDEuOC0xLjgzOSAyLjYwOS0uOTQxLjgzOS0xLjkxMyAxLjcwNS0xLjkxMyAyLjgzMmE3Ljg5MSA3Ljg5MSAwIDAwLjE1OSAxLjMzNWwuOTg1LS4xNzNhNy4yODIgNy4yODIgMCAwMS0uMTQ0LTEuMTYyYzAtLjY3OS43NjctMS4zNjIgMS41NzgtMi4wODUgMS4wMTktLjkwOSAyLjE3NC0xLjkzOCAyLjE3NC0zLjM1NkMxNS41IDcuOTM1IDE1LjE1MSA2IDExLjkyNCA2eiIvPjwvZz48L3N2Zz4=');
    }

    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMntmaWxsOiNmZmZ9PC9zdHlsZT48L2RlZnM+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgaWQ9Il8yNF9YXzI0X0JPWCIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxnIGlkPSJJQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTEyIDJhMTAgMTAgMCAxMDEwIDEwQTEwLjAxMSAxMC4wMTEgMCAwMDEyIDJ6bTAgMTlhOSA5IDAgMTE5LTkgOS4wMSA5LjAxIDAgMDEtOSA5eiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTExLjQyOSAxNi44NTdhLjcuNyAwIDAwLS43LjY4NC42OTQuNjk0IDAgMDAuNzE3LjY4NC42ODEuNjgxIDAgMDAuNy0uNjg0LjcwNi43MDYgMCAwMC0uNzE3LS42ODR6TTExLjkyNCA2YTkuODY1IDkuODY1IDAgMDAtMy4wODcuNTM0bC4zMjYuOTQ1QTkgOSAwIDAxMTEuOTI0IDdjMS43MDkgMCAyLjU3Ni41OTQgMi41NzYgMS43NjYgMCAuOTY5LS45MzUgMS44LTEuODM5IDIuNjA5LS45NDEuODM5LTEuOTEzIDEuNzA1LTEuOTEzIDIuODMyYTcuODkxIDcuODkxIDAgMDAuMTU5IDEuMzM1bC45ODUtLjE3M2E3LjI4MiA3LjI4MiAwIDAxLS4xNDQtMS4xNjJjMC0uNjc5Ljc2Ny0xLjM2MiAxLjU3OC0yLjA4NSAxLjAxOS0uOTA5IDIuMTc0LTEuOTM4IDIuMTc0LTMuMzU2QzE1LjUgNy45MzUgMTUuMTUxIDYgMTEuOTI0IDZ6Ii8+PC9nPjwvc3ZnPg==');
    }
  }

  &.bol-icon-list {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMSwuY2xzLTJ7ZmlsbDpub25lO30uY2xzLTF7c3Ryb2tlOiMwMTIxNjk7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PC9kZWZzPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjgiIHkxPSIxMiIgeDI9IjIyIiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSIyIiB5MT0iMTIiIHgyPSI1IiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iOCIgeDI9IjIyIiB5Mj0iOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjIiIHkxPSI4IiB4Mj0iNSIgeTI9IjgiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iMTYiIHgyPSIyMiIgeTI9IjE2Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iMiIgeTE9IjE2IiB4Mj0iNSIgeTI9IjE2Ii8+PHJlY3QgY2xhc3M9ImNscy0yIi8+PC9zdmc+');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMSwuY2xzLTJ7ZmlsbDpub25lO30uY2xzLTF7c3Ryb2tlOiNlMzE4Mzc7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PC9kZWZzPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjgiIHkxPSIxMiIgeDI9IjIyIiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSIyIiB5MT0iMTIiIHgyPSI1IiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iOCIgeDI9IjIyIiB5Mj0iOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjIiIHkxPSI4IiB4Mj0iNSIgeTI9IjgiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iMTYiIHgyPSIyMiIgeTI9IjE2Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iMiIgeTE9IjE2IiB4Mj0iNSIgeTI9IjE2Ii8+PHJlY3QgY2xhc3M9ImNscy0yIi8+PC9zdmc+');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMSwuY2xzLTJ7ZmlsbDpub25lO30uY2xzLTF7c3Ryb2tlOiMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PC9kZWZzPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjgiIHkxPSIxMiIgeDI9IjIyIiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSIyIiB5MT0iMTIiIHgyPSI1IiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iOCIgeDI9IjIyIiB5Mj0iOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjIiIHkxPSI4IiB4Mj0iNSIgeTI9IjgiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iMTYiIHgyPSIyMiIgeTI9IjE2Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iMiIgeTE9IjE2IiB4Mj0iNSIgeTI9IjE2Ii8+PHJlY3QgY2xhc3M9ImNscy0yIi8+PC9zdmc+');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMSwuY2xzLTJ7ZmlsbDpub25lO30uY2xzLTF7c3Ryb2tlOiNmZmY7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PC9kZWZzPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjgiIHkxPSIxMiIgeDI9IjIyIiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSIyIiB5MT0iMTIiIHgyPSI1IiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iOCIgeDI9IjIyIiB5Mj0iOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjIiIHkxPSI4IiB4Mj0iNSIgeTI9IjgiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iMTYiIHgyPSIyMiIgeTI9IjE2Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iMiIgeTE9IjE2IiB4Mj0iNSIgeTI9IjE2Ii8+PHJlY3QgY2xhc3M9ImNscy0yIi8+PC9zdmc+');
    }
    &--mdg {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMSwuY2xzLTJ7ZmlsbDpub25lO30uY2xzLTF7c3Ryb2tlOiM2NDY0NjQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PC9kZWZzPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjgiIHkxPSIxMiIgeDI9IjIyIiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSIyIiB5MT0iMTIiIHgyPSI1IiB5Mj0iMTIiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iOCIgeDI9IjIyIiB5Mj0iOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjIiIHkxPSI4IiB4Mj0iNSIgeTI9IjgiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSI4IiB5MT0iMTYiIHgyPSIyMiIgeTI9IjE2Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iMiIgeTE9IjE2IiB4Mj0iNSIgeTI9IjE2Ii8+PHJlY3QgY2xhc3M9ImNscy0yIi8+PC9zdmc+Cg==');
    }
  }

  &.bol-icon-success {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiMwMTIxNjk7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJfMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FUyIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkVTIj48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xMiwyMkExMCwxMCwwLDEsMSwyMiwxMiwxMC4wMTEsMTAuMDExLDAsMCwxLDEyLDIyWk0xMiwzYTksOSwwLDEsMCw5LDlBOS4wMTEsOS4wMTEsMCwwLDAsMTIsM1oiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iOS43MjcgMTYuOTU2IDUuMzk3IDEyLjYzNyA2LjEwMyAxMS45MjkgOS43MjcgMTUuNTQ0IDE3Ljg5NyA3LjM5NiAxOC42MDMgOC4xMDQgOS43MjcgMTYuOTU2Ii8+PC9nPjwvc3ZnPg==');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNlMzE4Mzc7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJfMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FUyIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkVTIj48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xMiwyMkExMCwxMCwwLDEsMSwyMiwxMiwxMC4wMTEsMTAuMDExLDAsMCwxLDEyLDIyWk0xMiwzYTksOSwwLDEsMCw5LDlBOS4wMTEsOS4wMTEsMCwwLDAsMTIsM1oiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iOS43MjcgMTYuOTU2IDUuMzk3IDEyLjYzNyA2LjEwMyAxMS45MjkgOS43MjcgMTUuNTQ0IDE3Ljg5NyA3LjM5NiAxOC42MDMgOC4xMDQgOS43MjcgMTYuOTU2Ii8+PC9nPjwvc3ZnPg==');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJfMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FUyIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkVTIj48cGF0aCBkPSJNMTIsMjJBMTAsMTAsMCwxLDEsMjIsMTIsMTAuMDExLDEwLjAxMSwwLDAsMSwxMiwyMlpNMTIsM2E5LDksMCwxLDAsOSw5QTkuMDExLDkuMDExLDAsMCwwLDEyLDNaIi8+PHBvbHlnb24gcG9pbnRzPSI5LjcyNyAxNi45NTYgNS4zOTcgMTIuNjM3IDYuMTAzIDExLjkyOSA5LjcyNyAxNS41NDQgMTcuODk3IDcuMzk2IDE4LjYwMyA4LjEwNCA5LjcyNyAxNi45NTYiLz48L2c+PC9zdmc+');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJfMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FUyIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkVTIj48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xMiwyMkExMCwxMCwwLDEsMSwyMiwxMiwxMC4wMTEsMTAuMDExLDAsMCwxLDEyLDIyWk0xMiwzYTksOSwwLDEsMCw5LDlBOS4wMTEsOS4wMTEsMCwwLDAsMTIsM1oiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iOS43MjcgMTYuOTU2IDUuMzk3IDEyLjYzNyA2LjEwMyAxMS45MjkgOS43MjcgMTUuNTQ0IDE3Ljg5NyA3LjM5NiAxOC42MDMgOC4xMDQgOS43MjcgMTYuOTU2Ii8+PC9nPjwvc3ZnPg==');
    }
    &--pos {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiMwMDc3MDA7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJfMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FUyIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkVTIj48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xMiwyMkExMCwxMCwwLDEsMSwyMiwxMiwxMC4wMTEsMTAuMDExLDAsMCwxLDEyLDIyWk0xMiwzYTksOSwwLDEsMCw5LDlBOS4wMTEsOS4wMTEsMCwwLDAsMTIsM1oiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iOS43MjcgMTYuOTU2IDUuMzk3IDEyLjYzNyA2LjEwMyAxMS45MjkgOS43MjcgMTUuNTQ0IDE3Ljg5NyA3LjM5NiAxOC42MDMgOC4xMDQgOS43MjcgMTYuOTU2Ii8+PC9nPjwvc3ZnPgo=');
    }
  }

  &.bol-icon-print {












    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiMwMTIxNjk7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJCVUZGRVIiPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FIiBkYXRhLW5hbWU9IklDT04gT1VUTElORSI+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNMTcsN1YzSDdWN0gydjhIN3Y2SDE3VjE1aDVWN1pNOCw0aDhWN0g4Wm04LDE2SDhWMTFoOFptNS02SDE3VjExaDJWMTBINXYxSDd2M0gzVjhIMjFaIi8+PC9nPjwvc3ZnPg==');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNlMzE4Mzc7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJCVUZGRVIiPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FIiBkYXRhLW5hbWU9IklDT04gT1VUTElORSI+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNMTcsN1YzSDdWN0gydjhIN3Y2SDE3VjE1aDVWN1pNOCw0aDhWN0g4Wm04LDE2SDhWMTFoOFptNS02SDE3VjExaDJWMTBINXYxSDd2M0gzVjhIMjFaIi8+PC9nPjwvc3ZnPg==');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJCVUZGRVIiPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FIiBkYXRhLW5hbWU9IklDT04gT1VUTElORSI+PHBhdGggZD0iTTE3LDdWM0g3VjdIMnY4SDd2NkgxN1YxNWg1VjdaTTgsNGg4VjdIOFptOCwxNkg4VjExaDhabTUtNkgxN1YxMWgyVjEwSDV2MUg3djNIM1Y4SDIxWiIvPjwvZz48L3N2Zz4=');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJCVUZGRVIiPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FIiBkYXRhLW5hbWU9IklDT04gT1VUTElORSI+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNMTcsN1YzSDdWN0gydjhIN3Y2SDE3VjE1aDVWN1pNOCw0aDhWN0g4Wm04LDE2SDhWMTFoOFptNS02SDE3VjExaDJWMTBINXYxSDd2M0gzVjhIMjFaIi8+PC9nPjwvc3ZnPg==');
    }
  }

  &.bol-icon-logout {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiMwMTIxNjk7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJfMjRfeF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgeCAyNCBCT1giPjxyZWN0IGNsYXNzPSJjbHMtMSIvPjwvZz48ZyBpZD0iSUNPTl9PVVRMSU5FIiBkYXRhLW5hbWU9IklDT04gT1VUTElORSI+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjE2IDMgMTYgNCA1IDQgNSAyMCAxNiAyMCAxNiAyMSA0IDIxIDQgMyAxNiAzIi8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjE0LjMwNyA3LjEyMSAyMCAxMiAxNC4zMDcgMTYuODc5IDEzLjY1NiAxNi4xMjEgMTcuODggMTIuNSA4IDEyLjUgOCAxMS41IDE3Ljg4IDExLjUgMTMuNjU2IDcuODc5IDE0LjMwNyA3LjEyMSIvPjwvZz48L3N2Zz4=');
    }
  }

  &.bol-icon-loading {
    position: relative;
    margin: 0 $spacing-x;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      background-image: none;
      background-size: contain;
      animation: icon-loading-animation 2s linear infinite;
    }

    &--blu {
      @extend .bol-icon-loading;
      &:before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20xmlns:xlink=%22http://www.w3.org/1999/xlink%22%20x=%220%22%20y=%220%22%20width=%2224%22%20height=%2224%22%20viewBox=%220,0,24,24%22%3E%3Cpath%20fill=%22%230053c2%22%20stroke=%22%22%20stroke-width=%22%22%20style=%22%22%20d=%22M11.9117276,22 C7.06862372,22.0011298 2.90808187,18.5905839 2,13.8749998 L3.00882724,13.6899998 C3.90914096,18.3580403 8.31588698,21.5276214 13.0721202,20.9280896 C17.8283535,20.3285577 21.2945553,16.1665773 20.9875764,11.4237459 C20.6805974,6.6809145 16.7062561,2.99213104 11.9117276,3 L11.9117276,2 C17.4833266,2 22,6.47715244 22,12 C22,17.5228473 17.4833266,22 11.9117276,22 Z%22%20/%3E%3C/svg%3E");
      }
    }

    &--blk {
      @extend .bol-icon-loading;
      &:before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20xmlns:xlink=%22http://www.w3.org/1999/xlink%22%20x=%220%22%20y=%220%22%20width=%2224%22%20height=%2224%22%20viewBox=%220,0,24,24%22%3E%3Cpath%20fill=%22%23000%22%20stroke=%22%22%20stroke-width=%22%22%20style=%22%22%20d=%22M11.9117276,22 C7.06862372,22.0011298 2.90808187,18.5905839 2,13.8749998 L3.00882724,13.6899998 C3.90914096,18.3580403 8.31588698,21.5276214 13.0721202,20.9280896 C17.8283535,20.3285577 21.2945553,16.1665773 20.9875764,11.4237459 C20.6805974,6.6809145 16.7062561,2.99213104 11.9117276,3 L11.9117276,2 C17.4833266,2 22,6.47715244 22,12 C22,17.5228473 17.4833266,22 11.9117276,22 Z%22%20/%3E%3C/svg%3E");
      }
    }

    &--wht {
      @extend .bol-icon-loading;
      &:before {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCSB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DTxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DTwvc3R5bGU+DTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMS45LDIyYy00LjgsMC05LTMuNC05LjktOC4xbDEtMC4yYzAuOSw0LjcsNS4zLDcuOCwxMC4xLDcuMmM0LjgtMC42LDguMi00LjgsNy45LTkuNUMyMC43LDYuNywxNi43LDMsMTEuOSwzDQlWMkMxNy41LDIsMjIsNi41LDIyLDEyUzE3LjUsMjIsMTEuOSwyMnoiLz4NPC9zdmc+DQ==");
      }
    }

    @at-root .#{$site-state-component} .spa-btn > [class*=bol-icon-loading] {
      &:first-child:not(:last-child) {
        margin-left: 0;
      }

      &:last-child:not(:first-child) {
        margin-right: 0;
      }
    }
  }

  &.bol-icon-edit {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxwYXRoIGQ9Ik0yMS4zNTQgNS44ODZhMi40MTEgMi40MTEgMCAwMC0uMTg0LTMuMTc4QTIuMzY2IDIuMzY2IDAgMDAxOS40NzQgMmEyLjM1NiAyLjM1NiAwIDAwLTEuNDExLjQ2NGwtLjAyNy0uMDE0TDIuNzI5IDE3Ljg0OCAyIDIybDQuMTMxLS43NDRMMjAuNjY5IDYuNjE3YTIuOTEgMi45MSAwIDAxLjM0OS40MjNsLTQuODMxIDQuODIyLjcwNy43MDggNC44MTctNC44MDhhLjg4OC44ODggMCAwMC4yODktLjY4IDEuOTYgMS45NiAwIDAwLS42NDYtMS4xOTZ6TTUuNjQyIDIwLjMyOGwtMi40MS40MzQuNDI3LTIuNDMxIDguMzc1LTguNDI1IDEuOTQ1IDIuMDI4em05LjA0Mi05LjFMMTIuNzM5IDkuMmw1LjUtNS41MjguMjU1LS4yNmExLjM2OCAxLjM2OCAwIDAxLjk4LS40MTIgMS4zNzYgMS4zNzYgMCAwMS45ODUuNDExIDEuNDE0IDEuNDE0IDAgMDEwIDEuOTkyeiIgZmlsbD0iIzAxMjE2OSIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiLz48L3N2Zz4=');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxwYXRoIGQ9Ik0yMS4zNTQgNS44ODZhMi40MTEgMi40MTEgMCAwMC0uMTg0LTMuMTc4QTIuMzY2IDIuMzY2IDAgMDAxOS40NzQgMmEyLjM1NiAyLjM1NiAwIDAwLTEuNDExLjQ2NGwtLjAyNy0uMDE0TDIuNzI5IDE3Ljg0OCAyIDIybDQuMTMxLS43NDRMMjAuNjY5IDYuNjE3YTIuOTEgMi45MSAwIDAxLjM0OS40MjNsLTQuODMxIDQuODIyLjcwNy43MDggNC44MTctNC44MDhhLjg4OC44ODggMCAwMC4yODktLjY4IDEuOTYgMS45NiAwIDAwLS42NDYtMS4xOTZ6TTUuNjQyIDIwLjMyOGwtMi40MS40MzQuNDI3LTIuNDMxIDguMzc1LTguNDI1IDEuOTQ1IDIuMDI4em05LjA0Mi05LjFMMTIuNzM5IDkuMmw1LjUtNS41MjguMjU1LS4yNmExLjM2OCAxLjM2OCAwIDAxLjk4LS40MTIgMS4zNzYgMS4zNzYgMCAwMS45ODUuNDExIDEuNDE0IDEuNDE0IDAgMDEwIDEuOTkyeiIgZmlsbD0iI2UzMTgzNyIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiLz48L3N2Zz4=');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxwYXRoIGQ9Ik0yMS4zNTQgNS44ODZhMi40MTEgMi40MTEgMCAwMC0uMTg0LTMuMTc4QTIuMzY2IDIuMzY2IDAgMDAxOS40NzQgMmEyLjM1NiAyLjM1NiAwIDAwLTEuNDExLjQ2NGwtLjAyNy0uMDE0TDIuNzI5IDE3Ljg0OCAyIDIybDQuMTMxLS43NDRMMjAuNjY5IDYuNjE3YTIuOTEgMi45MSAwIDAxLjM0OS40MjNsLTQuODMxIDQuODIyLjcwNy43MDggNC44MTctNC44MDhhLjg4OC44ODggMCAwMC4yODktLjY4IDEuOTYgMS45NiAwIDAwLS42NDYtMS4xOTZ6TTUuNjQyIDIwLjMyOGwtMi40MS40MzQuNDI3LTIuNDMxIDguMzc1LTguNDI1IDEuOTQ1IDIuMDI4em05LjA0Mi05LjFMMTIuNzM5IDkuMmw1LjUtNS41MjguMjU1LS4yNmExLjM2OCAxLjM2OCAwIDAxLjk4LS40MTIgMS4zNzYgMS4zNzYgMCAwMS45ODUuNDExIDEuNDE0IDEuNDE0IDAgMDEwIDEuOTkyeiIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiLz48L3N2Zz4=');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxwYXRoIGQ9Ik0yMS4zNTQgNS44ODZhMi40MTEgMi40MTEgMCAwMC0uMTg0LTMuMTc4QTIuMzY2IDIuMzY2IDAgMDAxOS40NzQgMmEyLjM1NiAyLjM1NiAwIDAwLTEuNDExLjQ2NGwtLjAyNy0uMDE0TDIuNzI5IDE3Ljg0OCAyIDIybDQuMTMxLS43NDRMMjAuNjY5IDYuNjE3YTIuOTEgMi45MSAwIDAxLjM0OS40MjNsLTQuODMxIDQuODIyLjcwNy43MDggNC44MTctNC44MDhhLjg4OC44ODggMCAwMC4yODktLjY4IDEuOTYgMS45NiAwIDAwLS42NDYtMS4xOTZ6TTUuNjQyIDIwLjMyOGwtMi40MS40MzQuNDI3LTIuNDMxIDguMzc1LTguNDI1IDEuOTQ1IDIuMDI4em05LjA0Mi05LjFMMTIuNzM5IDkuMmw1LjUtNS41MjguMjU1LS4yNmExLjM2OCAxLjM2OCAwIDAxLjk4LS40MTIgMS4zNzYgMS4zNzYgMCAwMS45ODUuNDExIDEuNDE0IDEuNDE0IDAgMDEwIDEuOTkyeiIgZmlsbD0iI2ZmZiIgZGF0YS1uYW1lPSJJQ09OIE9VVExJTkUiLz48L3N2Zz4=');
    }
  }

  &.bol-icon-delete {
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPi5zdDB7ZmlsbDojMDEyMTY5fTwvc3R5bGU+PHN3aXRjaD48Zz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTEuNSA4aDF2MTFoLTF6TTE0LjUgOGgxdjExaC0xek04LjUgOGgxdjExaC0xeiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yMSA1aC01VjJIOHYzSDN2MWgydjEzLjljMCAxLjIuOSAyLjEgMi4xIDIuMWg5LjhjMS4yIDAgMi4xLS45IDIuMS0yLjFWNmgyVjV6TTkgM2g2djJIOVYzem05IDE2LjljMCAuNi0uNSAxLjEtMS4xIDEuMUg3LjFjLS42IDAtMS4xLS41LTEuMS0xLjFWNmgxMnYxMy45eiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2c+PC9zd2l0Y2g+PC9zdmc+');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPi5zdDB7ZmlsbDojZTMxODM3fTwvc3R5bGU+PHN3aXRjaD48Zz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTEuNSA4aDF2MTFoLTF6TTE0LjUgOGgxdjExaC0xek04LjUgOGgxdjExaC0xeiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yMSA1aC01VjJIOHYzSDN2MWgydjEzLjljMCAxLjIuOSAyLjEgMi4xIDIuMWg5LjhjMS4yIDAgMi4xLS45IDIuMS0yLjFWNmgyVjV6TTkgM2g2djJIOVYzem05IDE2LjljMCAuNi0uNSAxLjEtMS4xIDEuMUg3LjFjLS42IDAtMS4xLS41LTEuMS0xLjFWNmgxMnYxMy45eiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2c+PC9zd2l0Y2g+PC9zdmc+');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHN3aXRjaD48Zz48cGF0aCBkPSJNMTEuNSA4aDF2MTFoLTF6TTE0LjUgOGgxdjExaC0xek04LjUgOGgxdjExaC0xeiIvPjxwYXRoIGQ9Ik0yMSA1aC01VjJIOHYzSDN2MWgydjEzLjljMCAxLjIuOSAyLjEgMi4xIDIuMWg5LjhjMS4yIDAgMi4xLS45IDIuMS0yLjFWNmgyVjV6TTkgM2g2djJIOVYzem05IDE2LjljMCAuNi0uNSAxLjEtMS4xIDEuMUg3LjFjLS42IDAtMS4xLS41LTEuMS0xLjFWNmgxMnYxMy45eiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2c+PC9zd2l0Y2g+PC9zdmc+');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHN3aXRjaD48Zz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTEuNSA4aDF2MTFoLTF6TTE0LjUgOGgxdjExaC0xek04LjUgOGgxdjExaC0xeiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yMSA1aC01VjJIOHYzSDN2MWgydjEzLjljMCAxLjIuOSAyLjEgMi4xIDIuMWg5LjhjMS4yIDAgMi4xLS45IDIuMS0yLjFWNmgyVjV6TTkgM2g2djJIOVYzem05IDE2LjljMCAuNi0uNSAxLjEtMS4xIDEuMUg3LjFjLS42IDAtMS4xLS41LTEuMS0xLjFWNmgxMnYxMy45eiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2c+PC9zd2l0Y2g+PC9zdmc+');
    }
  }

  &.bol-icon-account {

    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ze2ZpbGw6IzAwNTJjMjttaXgtYmxlbmQtbW9kZTptdWx0aXBseX08L3N0eWxlPjwvZGVmcz48ZyBzdHlsZT0iaXNvbGF0aW9uOmlzb2xhdGUiPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX0xheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiIvPjxnIGlkPSJwcmVmaXhfX0FkanVzdGVkX2NvcHkiIGRhdGEtbmFtZT0iQWRqdXN0ZWQgY29weSI+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTMiIGQ9Ik0xMiAyYTEwIDEwIDAgMTAxMCAxMEExMCAxMCAwIDAwMTIgMnptMCAxOWE5IDkgMCAxMTktOSA5IDkgMCAwMS05IDl6Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTMiIGQ9Ik0xMi41IDcuOTRWNmgtMXYxLjk0YTIuNTMgMi41MyAwIDAwLTIuNCAyLjI2YzAgMS4yOSAxIDIuMDYgMi44NCAyLjI5czIgMSAyIDEuMzFjMCAuNy0uODcgMS4zLTEuOSAxLjNzLTEuOS0uNi0xLjktMS4zdi0uM2gtMXYuM2EyLjUzIDIuNTMgMCAwMDIuNCAyLjI2VjE4aDF2LTEuOTRhMi41MyAyLjUzIDAgMDAyLjQtMi4yNmMwLTEuMjktMS0yLjA2LTIuODQtMi4zcy0yLTEtMi0xLjNjMC0uNy44Ny0xLjMgMS45LTEuM3MxLjkuNiAxLjkgMS4zdi4zaDF2LS4zYTIuNTMgMi41MyAwIDAwLTIuNC0yLjI2eiIvPjwvZz48L2c+PC9zdmc+');
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ze2ZpbGw6IzAxMjE2OTttaXgtYmxlbmQtbW9kZTptdWx0aXBseX08L3N0eWxlPjwvZGVmcz48ZyBzdHlsZT0iaXNvbGF0aW9uOmlzb2xhdGUiPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX0xheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiIvPjxnIGlkPSJwcmVmaXhfX0FkanVzdGVkX2NvcHkiIGRhdGEtbmFtZT0iQWRqdXN0ZWQgY29weSI+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTMiIGQ9Ik0xMiAyYTEwIDEwIDAgMTAxMCAxMEExMC4wMTEgMTAuMDExIDAgMDAxMiAyem0wIDE5YTkgOSAwIDExOS05IDkuMDExIDkuMDExIDAgMDEtOSA5eiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0zIiBkPSJNMTIuNSA3Ljk0VjZoLTF2MS45NGEyLjUyOSAyLjUyOSAwIDAwLTIuNCAyLjI2YzAgMS4yODkuOTU0IDIuMDYgMi44MzYgMi4yOTRDMTMuOSAxMi43NDEgMTMuOSAxMy41MSAxMy45IDEzLjhjMCAuNzA1LS44NyAxLjMtMS45IDEuM3MtMS45LS41OTUtMS45LTEuM3YtLjNoLTF2LjNhMi41MjkgMi41MjkgMCAwMDIuNCAyLjI2VjE4aDF2LTEuOTRhMi41MjkgMi41MjkgMCAwMDIuNC0yLjI2YzAtMS4yODgtLjk1Ni0yLjA2MS0yLjg0LTIuMy0xLjk2LS4yNDQtMS45Ni0xLjAxMi0xLjk2LTEuMyAwLS43Ljg3LTEuMyAxLjktMS4zczEuOS41OTUgMS45IDEuM3YuM2gxdi0uM2EyLjUyOSAyLjUyOSAwIDAwLTIuNC0yLjI2eiIvPjwvZz48L2c+PC9zdmc+');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ze2ZpbGw6I2UzMTgzNzttaXgtYmxlbmQtbW9kZTptdWx0aXBseX08L3N0eWxlPjwvZGVmcz48ZyBzdHlsZT0iaXNvbGF0aW9uOmlzb2xhdGUiPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX0xheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiIvPjxnIGlkPSJwcmVmaXhfX0FkanVzdGVkX2NvcHkiIGRhdGEtbmFtZT0iQWRqdXN0ZWQgY29weSI+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTMiIGQ9Ik0xMiAyYTEwIDEwIDAgMTAxMCAxMEExMC4wMTEgMTAuMDExIDAgMDAxMiAyem0wIDE5YTkgOSAwIDExOS05IDkuMDExIDkuMDExIDAgMDEtOSA5eiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0zIiBkPSJNMTIuNSA3Ljk0VjZoLTF2MS45NGEyLjUyOSAyLjUyOSAwIDAwLTIuNCAyLjI2YzAgMS4yODkuOTU0IDIuMDYgMi44MzYgMi4yOTRDMTMuOSAxMi43NDEgMTMuOSAxMy41MSAxMy45IDEzLjhjMCAuNzA1LS44NyAxLjMtMS45IDEuM3MtMS45LS41OTUtMS45LTEuM3YtLjNoLTF2LjNhMi41MjkgMi41MjkgMCAwMDIuNCAyLjI2VjE4aDF2LTEuOTRhMi41MjkgMi41MjkgMCAwMDIuNC0yLjI2YzAtMS4yODgtLjk1Ni0yLjA2MS0yLjg0LTIuMy0xLjk2LS4yNDQtMS45Ni0xLjAxMi0xLjk2LTEuMyAwLS43Ljg3LTEuMyAxLjktMS4zczEuOS41OTUgMS45IDEuM3YuM2gxdi0uM2EyLjUyOSAyLjUyOSAwIDAwLTIuNC0yLjI2eiIvPjwvZz48L2c+PC9zdmc+');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ze21peC1ibGVuZC1tb2RlOm11bHRpcGx5fTwvc3R5bGU+PC9kZWZzPjxnIHN0eWxlPSJpc29sYXRpb246aXNvbGF0ZSI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgaWQ9InByZWZpeF9fTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIi8+PGcgaWQ9InByZWZpeF9fQWRqdXN0ZWRfY29weSIgZGF0YS1uYW1lPSJBZGp1c3RlZCBjb3B5Ij48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMyIgZD0iTTEyIDJhMTAgMTAgMCAxMDEwIDEwQTEwLjAxMSAxMC4wMTEgMCAwMDEyIDJ6bTAgMTlhOSA5IDAgMTE5LTkgOS4wMTEgOS4wMTEgMCAwMS05IDl6Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTMiIGQ9Ik0xMi41IDcuOTRWNmgtMXYxLjk0YTIuNTI5IDIuNTI5IDAgMDAtMi40IDIuMjZjMCAxLjI4OS45NTQgMi4wNiAyLjgzNiAyLjI5NEMxMy45IDEyLjc0MSAxMy45IDEzLjUxIDEzLjkgMTMuOGMwIC43MDUtLjg3IDEuMy0xLjkgMS4zcy0xLjktLjU5NS0xLjktMS4zdi0uM2gtMXYuM2EyLjUyOSAyLjUyOSAwIDAwMi40IDIuMjZWMThoMXYtMS45NGEyLjUyOSAyLjUyOSAwIDAwMi40LTIuMjZjMC0xLjI4OC0uOTU2LTIuMDYxLTIuODQtMi4zLTEuOTYtLjI0NC0xLjk2LTEuMDEyLTEuOTYtMS4zIDAtLjcuODctMS4zIDEuOS0xLjNzMS45LjU5NSAxLjkgMS4zdi4zaDF2LS4zYTIuNTI5IDIuNTI5IDAgMDAtMi40LTIuMjZ6Ii8+PC9nPjwvZz48L3N2Zz4=');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ze2ZpbGw6I2ZmZjttaXgtYmxlbmQtbW9kZTptdWx0aXBseX08L3N0eWxlPjwvZGVmcz48ZyBzdHlsZT0iaXNvbGF0aW9uOmlzb2xhdGUiPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX0xheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiIvPjxnIGlkPSJwcmVmaXhfX0FkanVzdGVkX2NvcHkiIGRhdGEtbmFtZT0iQWRqdXN0ZWQgY29weSI+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTMiIGQ9Ik0xMiAyYTEwIDEwIDAgMTAxMCAxMEExMC4wMTEgMTAuMDExIDAgMDAxMiAyem0wIDE5YTkgOSAwIDExOS05IDkuMDExIDkuMDExIDAgMDEtOSA5eiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0zIiBkPSJNMTIuNSA3Ljk0VjZoLTF2MS45NGEyLjUyOSAyLjUyOSAwIDAwLTIuNCAyLjI2YzAgMS4yODkuOTU0IDIuMDYgMi44MzYgMi4yOTRDMTMuOSAxMi43NDEgMTMuOSAxMy41MSAxMy45IDEzLjhjMCAuNzA1LS44NyAxLjMtMS45IDEuM3MtMS45LS41OTUtMS45LTEuM3YtLjNoLTF2LjNhMi41MjkgMi41MjkgMCAwMDIuNCAyLjI2VjE4aDF2LTEuOTRhMi41MjkgMi41MjkgMCAwMDIuNC0yLjI2YzAtMS4yODgtLjk1Ni0yLjA2MS0yLjg0LTIuMy0xLjk2LS4yNDQtMS45Ni0xLjAxMi0xLjk2LTEuMyAwLS43Ljg3LTEuMyAxLjktMS4zczEuOS41OTUgMS45IDEuM3YuM2gxdi0uM2EyLjUyOSAyLjUyOSAwIDAwLTIuNC0yLjI2eiIvPjwvZz48L2c+PC9zdmc+');
    }
  }

  &.bol-icon-bank {

    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6IzAwNTJjMn08L3N0eWxlPjwvZGVmcz48ZyBpZD0icHJlZml4X19JQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjAuNDIgMTl2LTIuN2gtMS42N1Y4LjQyTDIxIDkuNTRsLjQ1LS44OUwxMiAzLjk0IDIuNTQgOC42NWwuNDYuODkgMi4yNS0xLjEydjcuODhIMy41OFYxOUgydjFoMjB2LTF6TTEyIDUuMDVsNS43NSAyLjg3djguMzhINi4yNVY3Ljkyek00LjU4IDE3LjNoMTQuODRWMTlINC41OHoiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTkuODMgMTIuMnYuMjFhMS44NiAxLjg2IDAgMDAxLjY3IDEuN3YxLjIyaDF2LTEuMjJhMS44NiAxLjg2IDAgMDAxLjY3LTEuN2MwLTEtLjczLTEuNTctMi4xMS0xLjc0cy0xLjIzLS41OS0xLjIzLS43NmMwLS40LjU1LS43NSAxLjE3LS43NXMxLjE3LjM1IDEuMTcuNzV2LjIxaDF2LS4yMWExLjkgMS45IDAgMDAtMS42Ny0xLjdWN2gtMXYxLjIxYTEuOSAxLjkgMCAwMC0xLjY3IDEuN2MwIDEgLjczIDEuNTggMi4xMSAxLjc1czEuMjMuNTkgMS4yMy43NWMwIC40LS41NS43NS0xLjE3Ljc1cy0xLjE3LS4zNS0xLjE3LS43NXYtLjIxeiIvPjwvZz48L3N2Zz4=');
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6IzAxMjE2OX08L3N0eWxlPjwvZGVmcz48ZyBpZD0icHJlZml4X19JQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjAuNDE3IDE5di0yLjdIMTguNzVWOC40MTZsMi4yNjQgMS4xMjcuNDQ1LS45TDEyIDMuOTM3bC05LjQ1OSA0LjcxLjQ0NS45TDUuMjUgOC40MTZWMTYuM0gzLjU4M1YxOUgydjFoMjB2LTF6TTEyIDUuMDU0bDUuNzUgMi44NjRWMTYuM0g2LjI1VjcuOTE4ek00LjU4MyAxNy4zaDE0LjgzNFYxOUg0LjU4M3oiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTkuODMzIDEyLjJ2LjIwOWExLjg1IDEuODUgMCAwMDEuNjY3IDEuN3YxLjIxNmgxdi0xLjIxMmExLjg1IDEuODUgMCAwMDEuNjY3LTEuN2MwLS45NzEtLjcyOS0xLjU3NS0yLjEwNi0xLjc0Ny0xLjIyOC0uMTUzLTEuMjI4LS41ODktMS4yMjgtLjc1MyAwLS40LjU0NS0uNzUgMS4xNjctLjc1czEuMTY3LjM1IDEuMTY3Ljc1di4yMDhoMXYtLjIwOGExLjg3OCAxLjg3OCAwIDAwLTEuNjY3LTEuN1Y3aC0xdjEuMjE0YTEuODc4IDEuODc4IDAgMDAtMS42NjcgMS43YzAgLjk3LjcyOCAxLjU3MyAyLjEgMS43NDUgMS4yMy4xNTQgMS4yMy41OTEgMS4yMy43NTUgMCAuNC0uNTQ1Ljc0OS0xLjE2Ny43NDlzLTEuMTY3LS4zNS0xLjE2Ny0uNzQ5VjEyLjJ6Ii8+PC9nPjwvc3ZnPg==');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6I2UzMTgzN308L3N0eWxlPjwvZGVmcz48ZyBpZD0icHJlZml4X19JQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjAuNDE3IDE5di0yLjdIMTguNzVWOC40MTZsMi4yNjQgMS4xMjcuNDQ1LS45TDEyIDMuOTM3bC05LjQ1OSA0LjcxLjQ0NS45TDUuMjUgOC40MTZWMTYuM0gzLjU4M1YxOUgydjFoMjB2LTF6TTEyIDUuMDU0bDUuNzUgMi44NjRWMTYuM0g2LjI1VjcuOTE4ek00LjU4MyAxNy4zaDE0LjgzNFYxOUg0LjU4M3oiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTkuODMzIDEyLjJ2LjIwOWExLjg1IDEuODUgMCAwMDEuNjY3IDEuN3YxLjIxNmgxdi0xLjIxMmExLjg1IDEuODUgMCAwMDEuNjY3LTEuN2MwLS45NzEtLjcyOS0xLjU3NS0yLjEwNi0xLjc0Ny0xLjIyOC0uMTUzLTEuMjI4LS41ODktMS4yMjgtLjc1MyAwLS40LjU0NS0uNzUgMS4xNjctLjc1czEuMTY3LjM1IDEuMTY3Ljc1di4yMDhoMXYtLjIwOGExLjg3OCAxLjg3OCAwIDAwLTEuNjY3LTEuN1Y3aC0xdjEuMjE0YTEuODc4IDEuODc4IDAgMDAtMS42NjcgMS43YzAgLjk3LjcyOCAxLjU3MyAyLjEgMS43NDUgMS4yMy4xNTQgMS4yMy41OTEgMS4yMy43NTUgMCAuNC0uNTQ1Ljc0OS0xLjE2Ny43NDlzLTEuMTY3LS4zNS0xLjE2Ny0uNzQ5VjEyLjJ6Ii8+PC9nPjwvc3ZnPg==');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGcgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGQ9Ik0yMC40MTcgMTl2LTIuN0gxOC43NVY4LjQxNmwyLjI2NCAxLjEyNy40NDUtLjlMMTIgMy45MzdsLTkuNDU5IDQuNzEuNDQ1LjlMNS4yNSA4LjQxNlYxNi4zSDMuNTgzVjE5SDJ2MWgyMHYtMXpNMTIgNS4wNTRsNS43NSAyLjg2NFYxNi4zSDYuMjVWNy45MTh6TTQuNTgzIDE3LjNoMTQuODM0VjE5SDQuNTgzeiIvPjxwYXRoIGQ9Ik05LjgzMyAxMi4ydi4yMDlhMS44NSAxLjg1IDAgMDAxLjY2NyAxLjd2MS4yMTZoMXYtMS4yMTJhMS44NSAxLjg1IDAgMDAxLjY2Ny0xLjdjMC0uOTcxLS43MjktMS41NzUtMi4xMDYtMS43NDctMS4yMjgtLjE1My0xLjIyOC0uNTg5LTEuMjI4LS43NTMgMC0uNC41NDUtLjc1IDEuMTY3LS43NXMxLjE2Ny4zNSAxLjE2Ny43NXYuMjA4aDF2LS4yMDhhMS44NzggMS44NzggMCAwMC0xLjY2Ny0xLjdWN2gtMXYxLjIxNGExLjg3OCAxLjg3OCAwIDAwLTEuNjY3IDEuN2MwIC45Ny43MjggMS41NzMgMi4xIDEuNzQ1IDEuMjMuMTU0IDEuMjMuNTkxIDEuMjMuNzU1IDAgLjQtLjU0NS43NDktMS4xNjcuNzQ5cy0xLjE2Ny0uMzUtMS4xNjctLjc0OVYxMi4yeiIvPjwvZz48L3N2Zz4=');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6I2ZmZn08L3N0eWxlPjwvZGVmcz48ZyBpZD0icHJlZml4X19JQ09OX0FSVCIgZGF0YS1uYW1lPSJJQ09OIEFSVCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjAuNDE3IDE5di0yLjdIMTguNzVWOC40MTZsMi4yNjQgMS4xMjcuNDQ1LS45TDEyIDMuOTM3bC05LjQ1OSA0LjcxLjQ0NS45TDUuMjUgOC40MTZWMTYuM0gzLjU4M1YxOUgydjFoMjB2LTF6TTEyIDUuMDU0bDUuNzUgMi44NjRWMTYuM0g2LjI1VjcuOTE4ek00LjU4MyAxNy4zaDE0LjgzNFYxOUg0LjU4M3oiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTkuODMzIDEyLjJ2LjIwOWExLjg1IDEuODUgMCAwMDEuNjY3IDEuN3YxLjIxNmgxdi0xLjIxMmExLjg1IDEuODUgMCAwMDEuNjY3LTEuN2MwLS45NzEtLjcyOS0xLjU3NS0yLjEwNi0xLjc0Ny0xLjIyOC0uMTUzLTEuMjI4LS41ODktMS4yMjgtLjc1MyAwLS40LjU0NS0uNzUgMS4xNjctLjc1czEuMTY3LjM1IDEuMTY3Ljc1di4yMDhoMXYtLjIwOGExLjg3OCAxLjg3OCAwIDAwLTEuNjY3LTEuN1Y3aC0xdjEuMjE0YTEuODc4IDEuODc4IDAgMDAtMS42NjcgMS43YzAgLjk3LjcyOCAxLjU3MyAyLjEgMS43NDUgMS4yMy4xNTQgMS4yMy41OTEgMS4yMy43NTUgMCAuNC0uNTQ1Ljc0OS0xLjE2Ny43NDlzLTEuMTY3LS4zNS0xLjE2Ny0uNzQ5VjEyLjJ6Ii8+PC9nPjwvc3ZnPg==');
    }
  }

  &.bol-icon-invest {

    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6IzAwNTJjMn08L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBpZD0icHJlZml4X19fMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giLz48ZyBpZD0icHJlZml4X19JQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTEgMjFhOCA4IDAgMDEwLTE2VjRhOSA5IDAgMTA5IDloLTFhOCA4IDAgMDEtOCA4eiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTMgMmgtLjV2OS41SDIyVjExYTkgOSAwIDAwLTktOXptLjUgOC41VjNhOCA4IDAgMDE3LjUgNy41eiIvPjwvZz48L3N2Zz4=');
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6IzAxMjE2OX08L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBpZD0icHJlZml4X19fMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giLz48ZyBpZD0icHJlZml4X19JQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTEgMjFhOCA4IDAgMDEwLTE2VjRhOSA5IDAgMTA5IDloLTFhOC4wMDkgOC4wMDkgMCAwMS04IDh6Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTIiIGQ9Ik0xMyAyaC0uNXY5LjVIMjJWMTFhOS4wMSA5LjAxIDAgMDAtOS05em0uNSA4LjVWMy4wMTZhOC4wMSA4LjAxIDAgMDE3LjQ4NCA3LjQ4NHoiLz48L2c+PC9zdmc+');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6I2UzMTgzN308L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBpZD0icHJlZml4X19fMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giLz48ZyBpZD0icHJlZml4X19JQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTEgMjFhOCA4IDAgMDEwLTE2VjRhOSA5IDAgMTA5IDloLTFhOC4wMDkgOC4wMDkgMCAwMS04IDh6Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTIiIGQ9Ik0xMyAyaC0uNXY5LjVIMjJWMTFhOS4wMSA5LjAxIDAgMDAtOS05em0uNSA4LjVWMy4wMTZhOC4wMSA4LjAxIDAgMDE3LjQ4NCA3LjQ4NHoiLz48L2c+PC9zdmc+');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxnIGRhdGEtbmFtZT0iSUNPTiBPVVRMSU5FUyI+PHBhdGggZD0iTTExIDIxYTggOCAwIDAxMC0xNlY0YTkgOSAwIDEwOSA5aC0xYTguMDA5IDguMDA5IDAgMDEtOCA4eiIvPjxwYXRoIGQ9Ik0xMyAyaC0uNXY5LjVIMjJWMTFhOS4wMSA5LjAxIDAgMDAtOS05em0uNSA4LjVWMy4wMTZhOC4wMSA4LjAxIDAgMDE3LjQ4NCA3LjQ4NHoiLz48L2c+PC9zdmc+');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6I2ZmZn08L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBpZD0icHJlZml4X19fMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giLz48ZyBpZD0icHJlZml4X19JQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTEgMjFhOCA4IDAgMDEwLTE2VjRhOSA5IDAgMTA5IDloLTFhOC4wMDkgOC4wMDkgMCAwMS04IDh6Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fY2xzLTIiIGQ9Ik0xMyAyaC0uNXY5LjVIMjJWMTFhOS4wMSA5LjAxIDAgMDAtOS05em0uNSA4LjVWMy4wMTZhOC4wMSA4LjAxIDAgMDE3LjQ4NCA3LjQ4NHoiLz48L2c+PC9zdmc+');
    }
  }

  &.bol-icon-loan {

    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX3ByZWZpeF9fY2xzLTJ7ZmlsbDojMDA1MmMyfTwvc3R5bGU+PC9kZWZzPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGlkPSJwcmVmaXhfX3ByZWZpeF9fXzI0X1hfMjRfQk9YIiBkYXRhLW5hbWU9IjI0IFggMjQgQk9YIi8+PGcgaWQ9InByZWZpeF9fcHJlZml4X19JQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX3ByZWZpeF9fY2xzLTIiIGQ9Ik0yMS40NDUgOC4xYTEuODQ2IDEuODQ2IDAgMDAtMS4yMzctLjUyNiAxLjI4NSAxLjI4NSAwIDAwLS45MTMuMzc3bC02Ljc3NyA2LjkyOS0uMTE4IDIuNDc2IDIuNDcyLS4xNzcgNi43NzItNi45MjdBMS4yNzkgMS4yNzkgMCAwMDIyIDkuMzMzYTEuNzc3IDEuNzc3IDAgMDAtLjU1NS0xLjIzM3ptLS41MTUgMS40NTJsLTYuNTA3IDYuNjU2LS45Ny4wNy4wNDUtLjk3MSA2LjUxNS02LjY1NWEuMjkzLjI5MyAwIDAxLjIwNy0uMDc1Ljg5MS44OTEgMCAwMS41MjUuMjQuNzg4Ljc4OCAwIDAxLjI1NS41MjYuMjkzLjI5MyAwIDAxLS4wNy4yMDl6Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fcHJlZml4X19jbHMtMiIgZD0iTTE3IDIxSDNWM2gxNHY1aDFWMkgydjIwaDE2di01aC0xdjR6Ii8+PHBhdGggY2xhc3M9InByZWZpeF9fcHJlZml4X19jbHMtMiIgZD0iTTEyLjI3MyA4LjU3OVY4LjNBMi40MTcgMi40MTcgMCAwMDEwIDYuMTNWNC41SDl2MS42M0EyLjQxNyAyLjQxNyAwIDAwNi43MjcgOC4zYzAgMS4yMzYuOTEyIDEuOTc2IDIuNzA5IDIuMiAxLjgzNy4yMzEgMS44MzcuOTQzIDEuODM3IDEuMjExIDAgLjY1My0uODEyIDEuMjA2LTEuNzczIDEuMjA2cy0xLjc3My0uNTUzLTEuNzczLTEuMjA2di0uMjg0aC0xdi4yODRBMi40MTcgMi40MTcgMCAwMDkgMTMuODd2MS42M2gxdi0xLjYzYTIuNDE3IDIuNDE3IDAgMDAyLjI3My0yLjE2NWMwLTEuMjM1LS45MTMtMS45NzYtMi43MTMtMi4yLTEuODMzLS4yMzEtMS44MzMtLjk0My0xLjgzMy0xLjIwNSAwLS42NTMuODEyLTEuMjA2IDEuNzczLTEuMjA2czEuNzczLjU1MyAxLjc3MyAxLjIwNnYuMjg0eiIvPjwvZz48L3N2Zz4=');
    &--blu {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6IzAxMjE2OX08L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBpZD0icHJlZml4X19fMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giLz48ZyBpZD0icHJlZml4X19JQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjEuNDQ1IDguMWExLjg0NiAxLjg0NiAwIDAwLTEuMjM3LS41MjYgMS4yODUgMS4yODUgMCAwMC0uOTEzLjM3N2wtNi43NzcgNi45MjktLjExOCAyLjQ3NiAyLjQ3Mi0uMTc3IDYuNzcyLTYuOTI3QTEuMjc5IDEuMjc5IDAgMDAyMiA5LjMzM2ExLjc3NyAxLjc3NyAwIDAwLS41NTUtMS4yMzN6bS0uNTE1IDEuNDUybC02LjUwNyA2LjY1Ni0uOTcuMDcuMDQ1LS45NzEgNi41MTUtNi42NTVhLjI5My4yOTMgMCAwMS4yMDctLjA3NS44OTEuODkxIDAgMDEuNTI1LjI0Ljc4OC43ODggMCAwMS4yNTUuNTI2LjI5My4yOTMgMCAwMS0uMDcuMjA5eiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTcgMjFIM1YzaDE0djVoMVYySDJ2MjBoMTZ2LTVoLTF2NHoiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTEyLjI3MyA4LjU3OVY4LjNBMi40MTcgMi40MTcgMCAwMDEwIDYuMTNWNC41SDl2MS42M0EyLjQxNyAyLjQxNyAwIDAwNi43MjcgOC4zYzAgMS4yMzYuOTEyIDEuOTc2IDIuNzA5IDIuMiAxLjgzNy4yMzEgMS44MzcuOTQzIDEuODM3IDEuMjExIDAgLjY1My0uODEyIDEuMjA2LTEuNzczIDEuMjA2cy0xLjc3My0uNTUzLTEuNzczLTEuMjA2di0uMjg0aC0xdi4yODRBMi40MTcgMi40MTcgMCAwMDkgMTMuODd2MS42M2gxdi0xLjYzYTIuNDE3IDIuNDE3IDAgMDAyLjI3My0yLjE2NWMwLTEuMjM1LS45MTMtMS45NzYtMi43MTMtMi4yLTEuODMzLS4yMzEtMS44MzMtLjk0My0xLjgzMy0xLjIwNSAwLS42NTMuODEyLTEuMjA2IDEuNzczLTEuMjA2czEuNzczLjU1MyAxLjc3MyAxLjIwNnYuMjg0eiIvPjwvZz48L3N2Zz4=');
    }
    &--red {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6I2UzMTgzN308L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBpZD0icHJlZml4X19fMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giLz48ZyBpZD0icHJlZml4X19JQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjEuNDQ1IDguMWExLjg0NiAxLjg0NiAwIDAwLTEuMjM3LS41MjYgMS4yODUgMS4yODUgMCAwMC0uOTEzLjM3N2wtNi43NzcgNi45MjktLjExOCAyLjQ3NiAyLjQ3Mi0uMTc3IDYuNzcyLTYuOTI3QTEuMjc5IDEuMjc5IDAgMDAyMiA5LjMzM2ExLjc3NyAxLjc3NyAwIDAwLS41NTUtMS4yMzN6bS0uNTE1IDEuNDUybC02LjUwNyA2LjY1Ni0uOTcuMDcuMDQ1LS45NzEgNi41MTUtNi42NTVhLjI5My4yOTMgMCAwMS4yMDctLjA3NS44OTEuODkxIDAgMDEuNTI1LjI0Ljc4OC43ODggMCAwMS4yNTUuNTI2LjI5My4yOTMgMCAwMS0uMDcuMjA5eiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTcgMjFIM1YzaDE0djVoMVYySDJ2MjBoMTZ2LTVoLTF2NHoiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTEyLjI3MyA4LjU3OVY4LjNBMi40MTcgMi40MTcgMCAwMDEwIDYuMTNWNC41SDl2MS42M0EyLjQxNyAyLjQxNyAwIDAwNi43MjcgOC4zYzAgMS4yMzYuOTEyIDEuOTc2IDIuNzA5IDIuMiAxLjgzNy4yMzEgMS44MzcuOTQzIDEuODM3IDEuMjExIDAgLjY1My0uODEyIDEuMjA2LTEuNzczIDEuMjA2cy0xLjc3My0uNTUzLTEuNzczLTEuMjA2di0uMjg0aC0xdi4yODRBMi40MTcgMi40MTcgMCAwMDkgMTMuODd2MS42M2gxdi0xLjYzYTIuNDE3IDIuNDE3IDAgMDAyLjI3My0yLjE2NWMwLTEuMjM1LS45MTMtMS45NzYtMi43MTMtMi4yLTEuODMzLS4yMzEtMS44MzMtLjk0My0xLjgzMy0xLjIwNSAwLS42NTMuODEyLTEuMjA2IDEuNzczLTEuMjA2czEuNzczLjU1MyAxLjc3MyAxLjIwNnYuMjg0eiIvPjwvZz48L3N2Zz4=');
    }
    &--blk {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZGF0YS1uYW1lPSIyNCBYIDI0IEJPWCIvPjxnIGRhdGEtbmFtZT0iSUNPTiBPVVRMSU5FUyI+PHBhdGggZD0iTTIxLjQ0NSA4LjFhMS44NDYgMS44NDYgMCAwMC0xLjIzNy0uNTI2IDEuMjg1IDEuMjg1IDAgMDAtLjkxMy4zNzdsLTYuNzc3IDYuOTI5LS4xMTggMi40NzYgMi40NzItLjE3NyA2Ljc3Mi02LjkyN0ExLjI3OSAxLjI3OSAwIDAwMjIgOS4zMzNhMS43NzcgMS43NzcgMCAwMC0uNTU1LTEuMjMzem0tLjUxNSAxLjQ1MmwtNi41MDcgNi42NTYtLjk3LjA3LjA0NS0uOTcxIDYuNTE1LTYuNjU1YS4yOTMuMjkzIDAgMDEuMjA3LS4wNzUuODkxLjg5MSAwIDAxLjUyNS4yNC43ODguNzg4IDAgMDEuMjU1LjUyNi4yOTMuMjkzIDAgMDEtLjA3LjIwOXoiLz48cGF0aCBkPSJNMTcgMjFIM1YzaDE0djVoMVYySDJ2MjBoMTZ2LTVoLTF2NHoiLz48cGF0aCBkPSJNMTIuMjczIDguNTc5VjguM0EyLjQxNyAyLjQxNyAwIDAwMTAgNi4xM1Y0LjVIOXYxLjYzQTIuNDE3IDIuNDE3IDAgMDA2LjcyNyA4LjNjMCAxLjIzNi45MTIgMS45NzYgMi43MDkgMi4yIDEuODM3LjIzMSAxLjgzNy45NDMgMS44MzcgMS4yMTEgMCAuNjUzLS44MTIgMS4yMDYtMS43NzMgMS4yMDZzLTEuNzczLS41NTMtMS43NzMtMS4yMDZ2LS4yODRoLTF2LjI4NEEyLjQxNyAyLjQxNyAwIDAwOSAxMy44N3YxLjYzaDF2LTEuNjNhMi40MTcgMi40MTcgMCAwMDIuMjczLTIuMTY1YzAtMS4yMzUtLjkxMy0xLjk3Ni0yLjcxMy0yLjItMS44MzMtLjIzMS0xLjgzMy0uOTQzLTEuODMzLTEuMjA1IDAtLjY1My44MTItMS4yMDYgMS43NzMtMS4yMDZzMS43NzMuNTUzIDEuNzczIDEuMjA2di4yODR6Ii8+PC9nPjwvc3ZnPg==');
    }
    &--wht {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5wcmVmaXhfX2Nscy0ye2ZpbGw6I2ZmZn08L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBpZD0icHJlZml4X19fMjRfWF8yNF9CT1giIGRhdGEtbmFtZT0iMjQgWCAyNCBCT1giLz48ZyBpZD0icHJlZml4X19JQ09OX09VVExJTkVTIiBkYXRhLW5hbWU9IklDT04gT1VUTElORVMiPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMjEuNDQ1IDguMWExLjg0NiAxLjg0NiAwIDAwLTEuMjM3LS41MjYgMS4yODUgMS4yODUgMCAwMC0uOTEzLjM3N2wtNi43NzcgNi45MjktLjExOCAyLjQ3NiAyLjQ3Mi0uMTc3IDYuNzcyLTYuOTI3QTEuMjc5IDEuMjc5IDAgMDAyMiA5LjMzM2ExLjc3NyAxLjc3NyAwIDAwLS41NTUtMS4yMzN6bS0uNTE1IDEuNDUybC02LjUwNyA2LjY1Ni0uOTcuMDcuMDQ1LS45NzEgNi41MTUtNi42NTVhLjI5My4yOTMgMCAwMS4yMDctLjA3NS44OTEuODkxIDAgMDEuNTI1LjI0Ljc4OC43ODggMCAwMS4yNTUuNTI2LjI5My4yOTMgMCAwMS0uMDcuMjA5eiIvPjxwYXRoIGNsYXNzPSJwcmVmaXhfX2Nscy0yIiBkPSJNMTcgMjFIM1YzaDE0djVoMVYySDJ2MjBoMTZ2LTVoLTF2NHoiLz48cGF0aCBjbGFzcz0icHJlZml4X19jbHMtMiIgZD0iTTEyLjI3MyA4LjU3OVY4LjNBMi40MTcgMi40MTcgMCAwMDEwIDYuMTNWNC41SDl2MS42M0EyLjQxNyAyLjQxNyAwIDAwNi43MjcgOC4zYzAgMS4yMzYuOTEyIDEuOTc2IDIuNzA5IDIuMiAxLjgzNy4yMzEgMS44MzcuOTQzIDEuODM3IDEuMjExIDAgLjY1My0uODEyIDEuMjA2LTEuNzczIDEuMjA2cy0xLjc3My0uNTUzLTEuNzczLTEuMjA2di0uMjg0aC0xdi4yODRBMi40MTcgMi40MTcgMCAwMDkgMTMuODd2MS42M2gxdi0xLjYzYTIuNDE3IDIuNDE3IDAgMDAyLjI3My0yLjE2NWMwLTEuMjM1LS45MTMtMS45NzYtMi43MTMtMi4yLTEuODMzLS4yMzEtMS44MzMtLjk0My0xLjgzMy0xLjIwNSAwLS42NTMuODEyLTEuMjA2IDEuNzczLTEuMjA2czEuNzczLjU1MyAxLjc3MyAxLjIwNnYuMjg0eiIvPjwvZz48L3N2Zz4=');
    }
  }
































}
























