@import '_mixins.scss';


.row {
  &.row-collapse-out {

      padding: 0 !important;

      &>.column:first-child {
      padding-left: 0;
    }

      &>.column:last-child {
      padding-right: 0;
    }
  }
}

@include render-nest-row-col;
