@mixin render-segmented-button-group() {
  flex-wrap: nowrap;

  > .spa-btn {
    border-radius: 0;
    margin-left: 2px;
    margin-bottom: 0;
    width: auto;

    @media #{$small-only} {
      flex-grow: 1;
      flex-basis: 0px;
    }

    &:first-child {
      border-top-left-radius: $border-radius--btn--medium;
      border-bottom-left-radius: $border-radius--btn--medium;
      margin-left: 0;
    }

    &:last-child {
      border-top-right-radius: $border-radius--btn--medium;
      border-bottom-right-radius: $border-radius--btn--medium;
    }

    &.on {
      background-color: $color--secondary;
      color: $color-on--secondary;
      border-color: $color--secondary;
      box-shadow: $box-shadow; 
    }
  }
}
