


$row-nested-modifier-selector: '.row.nested';

@mixin nest-row-col-in-parent-without-padding($padding: 0, $margin-bottom: 0) {
  #{$row-nested-modifier-selector} {
    padding-left: $padding / 2;
    padding-right: $padding / 2;
    margin-bottom: $margin-bottom;

    > .column {
      padding-left: $padding / 2;
      padding-right: $padding / 2;
    }
  }
}

@mixin nest-row-col-in-parent-with-padding($padding: 0, $margin-bottom: 0) {
  #{$row-nested-modifier-selector} {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: $margin-bottom;

    &:last-child {
      margin-bottom: 0;
    }

    > .column {
      padding-left: 0;
      padding-right: 0;
    }

    > .column:first-child:not(:last-child) {
      padding-right: $padding / 2;
    }

      > .column:not(:first-child):not(:last-child) {
      padding-left: $padding / 2;
      padding-right: $padding / 2;
    }

    > .column:last-child:not(:first-child) {
      padding-left: $padding / 2;
    }
  }
}

@mixin nest-row-col($padding, $margin-bottom) {

  @include nest-row-col-in-parent-without-padding($margin-bottom: $margin-bottom);

    > #{$row-nested-modifier-selector} > .column {
    @include nest-row-col-in-parent-with-padding($margin-bottom: $margin-bottom);
  }
}

@mixin render-nest-row-col() {
  @each $breakpoint-name, $breakpoint-query in $breakpoint-list {
    @if $breakpoint-name == small-up {
      @include nest-row-col($padding: $spacing-x, $margin-bottom: $spacing-y);
    } @else {
      @media #{$breakpoint-query} {
        @if $breakpoint-name == 'medium-up' {
          @include nest-row-col($padding: $spacing-x--md, $margin-bottom: $spacing-y--md);
        }
        @if $breakpoint-name == 'large-up' {
          @include nest-row-col($padding: $spacing-x--lg, $margin-bottom: $spacing-y--lg);
        }
      }
    }
  }
}



