.bol-toggle {

  -webkit-appearance: none;
  -moz-appearance: none;
  background: 0 0;
  border: none;
  color: $color--body;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  position: relative;
  text-align: left;
  text-decoration: none;
  margin-bottom: 0;
  padding: 0;


  @include render-toggle($right: 2px, $color: $color-gray);

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: none;

    &::after {
      border-color: $color--body;
    }
  }

  @media #{$small-only} {
    display: block;
    width: 100%;
  }

  &:empty {
    display: inline-block;
    border: $border;
    padding-right: 0;
    width: 24px;
    height: 24px;
    vertical-align: text-bottom;

    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &.on {
      &::after {
        transform: translate(-50%, 0) rotate(-135deg);
      }
    }
  }

  &--large {
    font-family: $font--heading;
    font-weight: $font-weight--heading;
    font-size: $font-size--large;
    line-height: $line-height--large;
    padding-right: ($spacing-x / 2) + (2 * $width--chevron--large);

    &::after {
      width: $width--chevron--large;
      height: $width--chevron--large;
      border-width: 3px;
      right: 3px;
    }
  }

  &--xlarge {
    font-family: $font--heading;
    font-weight: $font-weight--heading;
    font-size: $font-size--xlarge;
    line-height: $line-height--xlarge;
    padding-right: ($spacing-x / 2) + (2 * $width--chevron--large);

    &::after {
      width: $width--chevron--large;
      height: $width--chevron--large;
      border-width: 3px;
      right: 3px;
    }
  }
}
