.bol-filter {
  $check-height: 8px;
  $check-width: 16px;
  display: block;
  margin-bottom: $spacing-y;
  width: 100%;

    &__input {
    display: inline-block;
    position: absolute;
    width: 0;
    opacity: 0;

    + .bol-filter__label {
      margin: 0;
    }

    &:focus {
      + .bol-filter__label {
        outline: $outline;
        outline-offset: -1px;
      }
    }

    &:checked {
      + .bol-filter__label {
        background-color: $color--hover;
        border-color: $color--border-hover;

        &::after {
          content: '';
          position: absolute;
          right: $border-radius--btn--medium;
          top: 50%;
          height: $check-height;
          width: $check-width;
          border-left: 2px solid $color--border-hover;
          border-bottom: 2px solid $color--border-hover;
          transform: translateY(-75%) rotate(-45deg);
        }
      }
    }
  }

  &__label {
    display: inline-block;
    background-color: $color-gray-s-05;
    border: $border;
    border-radius: $border-radius--btn--medium;
    position: relative;
    padding: $padding--btn--medium;
    padding-right: $border-radius--btn--medium + $check-width + $spacing-x;
    font-weight: bold;
    width: 100%;
  }
}
