.bol-reauth {
  background-color: $color-royal-three-s20;
  border-top: $border;
  border-bottom: $border;
  @include push-down;
  @include pad-in;

  &__section {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    @include push-down;

    @media #{$medium-up} {
      flex-direction: row;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__instructions {
    @media #{$medium-up} {
      @include make-flex-section(#{$flex-basis--smaller});
      margin-right: $flex-section--margin;
    }
  }

  &__fields {
    @media #{$medium-up} {
      @include make-flex-section(#{$flex-basis--larger});
    }

    input[type=password] {
      width: 180px;
    }
  }

  &__forgot {
    background: 0 0;
    border: none;
    color: $color--link;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    text-align: left;
    text-decoration: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: $spacing-y;

    &:hover {
      color: $color--link-hover;
      text-decoration: underline;
    }

    &:focus,
    &:active {
      color: $color--link-focus;
      text-decoration: none;
    }
  }
}
