﻿






.float-none{float:none!important}
.float-left{float:left!important}
.float-right{float:right!important}
.pull-left{float:left!important}
.pull-right{float:right!important}
.align-left{text-align:left!important}
.align-center{text-align:center!important}
.align-right{text-align:right!important}
.align-justify{text-align:justify!important}
.align-top{vertical-align:top!important}
.align-middle{vertical-align:middle!important}
.align-bottom{vertical-align:bottom!important}
.bold{font-weight:bold!important}
.italic{font-style:italic!important}
.underline{text-decoration:underline!important}
.uppercase{text-transform:uppercase}
.lowercase{text-transform:lowercase}
.no-wrap{white-space:nowrap!important}
.no-indent{margin-left:0!important;padding-left:0!important}
.no-bullets{list-style:none!important;list-style-image:none!important}
.position-fixed{position:fixed}
.position-relative{position:relative}
.position-static{position:static}
.show{display:block!important}
.hide{display:none!important}
.invisible{visibility:hidden}
.inline-block{display:inline-block}
.block-center{display:block;margin-left:auto;margin-right:auto}
.input-small{width:80px!important}
.input-medium{width:120px!important}
.input-large{width:225px!important}
.clear{clear:both}
.full-width{width:100%!important}
.half-width{width:50%!important}
.width-20-pc{width:20%!important}
.width-80-pc{width:80%!important}


.highlight{background-color:$color--hover!important}


.border{border:$border!important}
.border-top-1{border-top:$border!important}
.border-right-1{border-right:$border!important}
.border-bottom-1{border-bottom:$border!important}
.border-left-1{border-left:$border!important}
.border-0{border:0!important}
.border-top-0{border-top:0!important}
.border-right-0{border-right:0!important}
.border-bottom-0{border-bottom:0!important}
.border-left-0{border-left:0!important}
.dashed{border-style:dashed}
.dotted{border-style:dotted}


.padding-0{padding:0!important}
.padding-5{padding:5px!important}
.padding-10{padding:10px!important}
.padding-15{padding:15px!important}
.padding-20{padding:20px!important}
.padding-25{padding:25px!important}
.padding-30{padding:30px!important}
.padding-35{padding:35px!important}
.padding-40{padding:40px!important}
.padding-top-0{padding-top:0!important}
.padding-top-5{padding-top:5px!important}
.padding-top-10{padding-top:10px!important}
.padding-top-15{padding-top:15px!important}
.padding-top-20{padding-top:20px!important}
.padding-top-25{padding-top:25px!important}
.padding-top-30{padding-top:30px!important}
.padding-top-35{padding-top:35px!important}
.padding-top-40{padding-top:40px!important}
.padding-bottom-0{padding-bottom:0!important}
.padding-bottom-5{padding-bottom:5px!important}
.padding-bottom-10{padding-bottom:10px!important}
.padding-bottom-15{padding-bottom:15px!important}
.padding-bottom-20{padding-bottom:20px!important}
.padding-bottom-25{padding-bottom:25px!important}
.padding-bottom-30{padding-bottom:30px!important}
.padding-bottom-35{padding-bottom:35px!important}
.padding-bottom-40{padding-bottom:40px!important}
.padding-left-0{padding-left:0!important}
.padding-left-5{padding-left:5px!important}
.padding-left-10{padding-left:10px!important}
.padding-left-15{padding-left:15px!important}
.padding-left-20{padding-left:20px!important}
.padding-left-25{padding-left:25px!important}
.padding-left-30{padding-left:30px!important}
.padding-left-35{padding-left:35px!important}
.padding-left-40{padding-left:40px!important}
.padding-right-0{padding-right:0!important}
.padding-right-5{padding-right:5px!important}
.padding-right-10{padding-right:10px!important}
.padding-right-15{padding-right:15px!important}
.padding-right-20{padding-right:20px!important}
.padding-right-25{padding-right:25px!important}
.padding-right-30{padding-right:30px!important}
.padding-right-35{padding-right:35px!important}
.padding-right-40{padding-right:40px!important}
.margin-auto{margin:auto!important}
.margin-0{margin:0!important}
.margin-5{margin:5px!important}
.margin-10{margin:10px!important}
.margin-15{margin:15px!important}
.margin-20{margin:20px!important}
.margin-25{margin:25px!important}
.margin-30{margin:30px!important}
.margin-35{margin:35px!important}
.margin-40{margin:40px!important}
.margin-top-0{margin-top:0!important}
.margin-top-5{margin-top:5px!important}
.margin-top-10{margin-top:10px!important}
.margin-top-15{margin-top:15px!important}
.margin-top-20{margin-top:20px!important}
.margin-top-25{margin-top:25px!important}
.margin-top-30{margin-top:30px!important}
.margin-top-35{margin-top:35px!important}
.margin-top-40{margin-top:40px!important}
.margin-bottom-0{margin-bottom:0!important}
.margin-bottom-5{margin-bottom:5px!important}
.margin-bottom-10{margin-bottom:10px!important}
.margin-bottom-15{margin-bottom:15px!important}
.margin-bottom-20{margin-bottom:20px!important}
.margin-bottom-25{margin-bottom:25px!important}
.margin-bottom-30{margin-bottom:30px!important}
.margin-bottom-35{margin-bottom:35px!important}
.margin-bottom-40{margin-bottom:40px!important}
.margin-left-0{margin-left:0!important}
.margin-left-5{margin-left:5px!important}
.margin-left-10{margin-left:10px!important}
.margin-left-15{margin-left:15px!important}
.margin-left-20{margin-left:20px!important}
.margin-left-25{margin-left:25px!important}
.margin-left-30{margin-left:30px!important}
.margin-left-35{margin-left:35px!important}
.margin-left-40{margin-left:40px!important}
.margin-right-0{margin-right:0!important}
.margin-right-5{margin-right:5px!important}
.margin-right-10{margin-right:10px!important}
.margin-right-15{margin-right:15px!important}
.margin-right-20{margin-right:20px!important}
.margin-right-25{margin-right:25px!important}
.margin-right-30{margin-right:30px!important}
.margin-right-35{margin-right:35px!important}
.margin-right-40{margin-right:40px!important}


.overflow{overflow:auto}
.overflow-y{overflow-y:auto;overflow-x:hidden}
.overflow-x{overflow-x:auto;overflow-y:hidden}
.overflow-hidden{overflow:hidden}


.sentence-case {
  text-transform: lowercase;

  &::first-letter { 
    text-transform: uppercase;
  }
}


.flex {
  display: flex;

  &-justify {
    &-start {
      justify-content: flex-start !important;
    }
    &-center {
      justify-content: center !important;
    }
    &-end {
      justify-content: flex-end !important;
    }
    &-between {
      justify-content: space-between;
    }
    &-around {
      justify-content: space-around;
    }
  }

  &-align {
    &-start {
      align-items: flex-start !important;
    }
    &-end {
      align-items: flex-end !important;
    }
    &-center {
      align-items: center !important;
    }
  }

  &-column {
    flex-direction: column;
  }

  &-row {
    flex-direction: row;
  }

  @media #{$medium-up} {
    &-justify {
      &-start {
        &--at-medium-up {
          justify-content: flex-start !important;
        }
      }
      &-center {
        &--at-medium-up {
          justify-content: center !important;
        }
      }
      &-end {
        &--at-medium-up {
          justify-content: flex-end !important;
        }
      }
    }

    &-align {
      &-start {
        &--at-medium-up {
          align-items: flex-start !important;
        }
      }
      &-end {
        &--at-medium-up {
          align-items: flex-start !important;
        }
      }
      &-center {
        &--at-medium-up {
          align-items: flex-start !important;
        }
      }
    }
  }
}




.push-down {
  @include push-down($important: true);
}



.hide--mobile {
  @media #{$small-only} {
    display: none !important;
  }
}

.hide--desktop {
  @media #{$medium-up} {
    display: none !important;
  }
}
