.bol-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  margin-bottom: $spacing-y;
  width: 100%;

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style-type: none;
    justify-content: center;
    padding: $padding--item;
    padding-top: 0;
    padding-bottom: 0;
    min-width: 60px;

    &:first-child {
      padding-left: 0;
    }

    &:focus {
      outline-offset: -1px;
    }

    &--active {
      font-weight: bold;
    }

    &--disabled {
      &, &:hover, &:focus, &:active, &:visited {
        color: $color-on--disabled !important;
        text-decoration: none;
      }
    }
  }

  > li.bol-nav__item {
    padding: 0 !important;

    &:first-child {
      > a {
        padding-left: 0;
      }
    }

    > a {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex-basis: auto; 
      justify-content: center;
      padding: 0px 10px;
      width: 100%;

      &:focus {
        outline-offset: -1px;
      }
    }

    &--disabled {
      > a, > a:hover, > a:focus, > a:active, > a:visited {
        color: $color-on--disabled !important;
        text-decoration: none;
      }
    }
  }

  &--stacked {
    flex-direction: column;

    > .bol-nav__item {
      justify-content: flex-start;
      padding: $padding--item;
      width: 100%;

      &:first-child {
        padding: $padding--item;
      }
    }

    > li.bol-nav__item {

      > a {
        justify-content: flex-start;
        padding: $padding--item !important;
      }
    }
  }

  &--pipes {

    > .bol-nav__item {
      border-left: $border;

      &:first-child {
        border-left: none;
      }

      > a {
        border-left: $border;

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  &--lined {
    flex-direction: column;

    > .bol-nav__item {
      border-bottom: $border;
      padding: $padding--item;
      justify-content: flex-start;

      &:first-child {
        border-top: $border;
      }
    }

    > li.bol-nav__item {
      > a {
        justify-content: flex-start;
        padding: $padding--item !important;
      }
    }
  }  
}
