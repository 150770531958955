.bol-filter-group {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  @include push-down;

  @media #{$medium-up} {
    flex-direction: row;
    align-items: center;
  }

  &__item {
    margin-bottom: $margin-bottom;

    >*:last-child {
      margin-bottom: 0;
    }

    @media #{$medium-up} {
      margin-left: $spacing-x--lg;
      margin-bottom: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--cfg-a {

    @media #{$medium-up} {
      .bol-filter-group__item {
        &:not(:first-child):last-child {
          margin-left: auto;
        }
      }
    }
  }
}
