



@mixin box-sizing($propertyValue: border-box) {

  box-sizing: $propertyValue;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}









@mixin ada-hidden {
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
  display: inline-block;
  position: absolute;
}

@mixin push-down($important: false) {
  @if $important {
    margin-bottom: $margin-bottom !important;

      @media #{$medium-up} {
      margin-bottom: $margin-bottom--md !important;
    }

      @media #{$large-up} {
      margin-bottom: $margin-bottom--lg !important;
    }
  } @else {
    margin-bottom: $margin-bottom;

      @media #{$medium-up} {
      margin-bottom: $margin-bottom--md;
    }

      @media #{$large-up} {
      margin-bottom: $margin-bottom--lg;
    }
  }
}

@mixin pad-in($important: false) {
  @if $important {
    padding: $padding !important;

      @media #{$medium-up} {
      padding: $padding--md !important;
    }

      @media #{$large-up} {
      padding: $padding--lg !important;
    }
  } @else {
    padding: $padding;

      @media #{$medium-up} {
      padding: $padding--md;
    }

      @media #{$large-up} {
      padding: $padding--lg;
    }
  }
}


@mixin make-flex-section($flex-basis: auto) {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: $flex-basis;
}

@mixin render-close-button($top: 0, $right: 0, $bottom: auto, $left: auto, $width: 18px, $height: 18px) {
  background: none;
  border: 0;
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  cursor: pointer;
  z-index: 1;
  width: $width;
  height: $height;
  padding: 0;
  margin: 0;

  &::before,
  &::after {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    top: 45%;
    left: 0;
    background-color: $color-gray;
    transition: $transition;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:focus,
  &:hover {

    &::before,
    &::after {
      background-color: $color-black;
    }
  }
}



@mixin render-toggle($top: 40%, $right: 0, $bottom: auto, $left: auto, $width: $width--chevron, $padding: ($spacing-x / 2) + (2 * $width--chevron), $margin: 0, $color: $color--link) {

  position: relative;
  padding-right: $padding;

  &::after {
    content: '';
    position: absolute;
    margin-left: $margin;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    width: $width;
    height: $width;
    border-right: 2px solid $color;
    border-bottom: 2px solid $color;
    transform: translateY(-50%) rotate(45deg);
    transition: $transition;
  }

  &.on {
    &::after {
      transform: rotate(-135deg);
    }
  }
}





@mixin render-action($top: 50%, $right: 0, $bottom: auto, $left: auto, $width: $spacing--chevron, $padding: 5px + $spacing--chevron, $margin: 0, $color: $color--link) {

  position: relative;
  padding-right: $padding;

  &::after {
    content: '';
    position: absolute;
    margin-left: $margin;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    width: $width;
    height: $width;
    border-right: 2px solid $color;
    border-bottom: 2px solid $color;
    transform: translateY(-50%) rotate(-45deg);
  }
}

@mixin render-color-tab ($color) {


  @media #{$small-only} {
    border-top: $border-radius solid $color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  @media #{$medium-up} {
    border-left: $border-radius solid $color;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}



@mixin render-dot($width: $width--dot, $height: $width--dot, $top: 50%, $left: 50%, $color: $color-black) {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: $top;
    left: $left;
    transform: translateX(-50%) translateY(-50%);
    background-color: $color;
    border-radius: 50%;
    width: $width;
    height: $height;
  }
}

@mixin paint-dot($color: $color-black) {
  &::before {
    background-color: $color;
  }
}


































































































