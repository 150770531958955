.bol-list-group {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0; 
  padding: 0;
  width: 100%;

    &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: $border;
    padding: $spacing-y 0;
    position: relative;
    width: 100%;

    &:first-child {
      border-top: none;
    }

    > .bol-list-group { 
      border-top: $border;
      margin-top: $spacing-y;

      .bol-list-group__item {
        padding-left: $spacing-x;

          &:last-child {
          padding-bottom: 0;
        }
      }
    }



    @at-root .#{$site-state-component} li.bol-list-group__item--action > a,
    &--action {
      @include render-action($right: $spacing--chevron, $padding: $spacing-x + $spacing--chevron + $spacing-x);

      &:hover {
        text-decoration: none;

        &::after {
          border-color: $color--link-hover;
        }
      }

      &:focus {
        &::after {
          border-color: $color--link-focus;
        }
      }
    }

    @at-root .#{$site-state-component} li.bol-list-group__item--toggle > a,
    &--toggle {
      @include render-toggle($top: 24px, $color: $color--inactive);
      min-height: 48px;
    }

    @at-root .#{$site-state-component} {
      li.bol-list-group__item--action,
      li.bol-list-group__item--toggle {
        padding: 0;

        &::after {
          display: none;
        }

        > a {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          padding: $spacing-y 0;
          padding-right: $spacing-x + $spacing--chevron + $spacing-x;
        }
      }

      li.bol-list-group__item--toggle > a,
      a.bol-list-group__item--toggle {
        color: $color--body;

        &:hover {
          color: $color--link-hover;
          text-decoration: none;

            &::after {
            border-color: $color--link-hover;
          }
        }

          &:focus {
          &::after {
            border-color: $color--link-focus;
          }
        }
      }

      li.bol-list-group__item--toggle {
        &.on {
          > a {
            &::after {
              transform: rotate(-135deg);
            }
          }
        }
      }
    }

        &--centered {
      justify-content: center;
      align-items: center;
    }

    &--cfg-a {

      .bol-list-group__value {
        text-align: left;
      }
    }

    &--cfg-b {

      .bol-list-group__key {
        @include make-flex-section(#{$flex-basis--half});
      }

      .bol-list-group__value {
        @include make-flex-section(#{$flex-basis--smaller-half});
      }
    }

    &--cfg-c {

      flex-direction: column;

      .bol-list-group__key {
        @include make-flex-section();
      }

      .bol-list-group__value {
        @include make-flex-section();
        margin-left: 0;
        text-align: left;
      }

      @media #{$medium-up} {
        flex-direction: row;

        .bol-list-group__key {
          @include make-flex-section(#{$flex-basis--larger});
        }

        .bol-list-group__value {
          @include make-flex-section(#{$flex-basis--smaller});
          margin-left: $flex-section--margin;
          text-align: right;
        }
      }
    }

    &--cfg-d {

      .bol-list-group__key {
        @include make-flex-section(#{$flex-basis--smaller});
      }

      .bol-list-group__value {
        @include make-flex-section(#{$flex-basis--larger});
        text-align: left;
      }
    }

    &--cfg-e {

      .bol-list-group__key {
        @include make-flex-section(#{$flex-basis--smaller});
      }

      .bol-list-group__value {
        @include make-flex-section(#{$flex-basis--larger});
      }
    }

    &--cfg-f {

      .bol-list-group__key {
        @include make-flex-section();
        flex-shrink: 1;
      }

      .bol-list-group__value {
        @include make-flex-section();
      }
    }

    &--cfg-g {

      @media #{$small-only} {
        .bol-list-group__key {
          @include make-flex-section();
          flex-shrink: 1;
        }

          .bol-list-group__value {
          @include make-flex-section();
        }
      }
    }

        &--heading {

      padding-top: 0;

      > *:first-child,
      > .bol-list-group__key {
        padding: $spacing-y 0 $spacing-y $spacing-x;
      }

      > .bol-list-group__value {
        padding: $spacing-y 0;
      }

      @media #{$ie-only} {
        > .bol-list-group__value {

          flex-basis: calc(35% - 24px);
        }
      }

      > .bol-list-group {
        margin-top: 0;
        padding-left: $spacing-x;
      }
    }

    @each $color-name, $color-value in $bol-colors {
      &--#{$color-name} {
        border-top: none;
        border-left: 4px solid $color-value;
        padding-left: $spacing-x;
        margin-bottom: $spacing-y;

        &:last-child {
          margin-bottom: 0;
        }

        &.bol-list-group__item--heading {
          border-left: none;
          padding-left: 0;

          > *:first-child,
          > .bol-list-group__key {
            border-left: 4px solid $color-value;
            padding-left: $spacing-x;
          }
        }
      }
    }

    &--dot {
      padding-left: $spacing-x + $width--dot + $spacing-x !important;
      @include render-dot($left: $spacing-x + ($width--dot / 2));
      @for $i from 0 to length($bol-colors) {
        &-#{$i} {
          @extend .bol-list-group__item--dot;
          @include paint-dot(nth(map-values($bol-colors), $i + 1));
        }
      }
    }
  }

  &--unlined {

    .bol-list-group__item {
      border-color: transparent; 
    }
  }

  .bol-list-group__item {

    &--dashed {
      border-top: 1px dashed $color--border;
    }

    &--separated {
      border-top: $border;
    }
  }

  &__key {
    @include make-flex-section(#{$flex-basis--larger});

    > .bol-toggle {
      width: auto;
    }
  }

  &__value {
    @include make-flex-section(#{$flex-basis--smaller});
    margin-left: $flex-section--margin;
    text-align: right;
  }

  &--horizontal {
    flex-direction: row;

    .bol-list-group__item {
      border: none;
    }
  }

  &--summary {
    .bol-list-group__key {
      @include make-flex-section(#{$flex-basis--smaller-half});
    }

    .bol-list-group__value {
      @include make-flex-section(#{$flex-basis--smaller-half});
      text-align: left;
    }

    @media #{$medium-up} {
      .bol-list-group__key {
        @include make-flex-section(#{$flex-basis--smaller});
      }

      .bol-list-group__value {
        @include make-flex-section(#{$flex-basis--larger});
      }
    }
  }

  &--bordered {
    border-top: $border;
    border-bottom: $border;
  }

  &--compact {
    .bol-list-group__item {
      padding: $spacing-y--compact 0;
    }
  }
}
