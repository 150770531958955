.bol-tile {
  $spacing--tile: 10px;
  $tiles-per-row: 3;
  $tiles-per-row--md: 5;
  $min-width--tile: calc((#{$small-min-width} - (#{$tiles-per-row} * (#{$spacing--tile} + 2px))) / #{$tiles-per-row});
  $min-width--tile--md: calc((#{$small-min-width} - (#{$tiles-per-row--md} * (#{$spacing--tile} + 2px))) / #{$tiles-per-row});
  $width--tile: calc((100% - (#{$tiles-per-row} * (#{$spacing--tile} + 2px))) / #{$tiles-per-row});
  $width--tile--md: calc((100% - (#{$tiles-per-row--md} * (#{$spacing--tile} + 2px))) / #{$tiles-per-row--md});
  background-color: $color--base;
  box-shadow: $box-shadow;
  border: $border;
  border-top-width: 2px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: $spacing--tile;
  margin-right: $spacing--tile;
  margin-bottom: $spacing--tile;
  overflow: hidden;
  min-width: $min-width--tile;
  width: $width--tile;
  max-width: $width--tile;
  max-height: 100%;
  min-height: 150px;
  transition: $transition;

  &:hover {
    background-color: $color--hover;
    border-color: $color--active;
    color: inherit;
    text-decoration: none;
  }

  &-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    min-width: 320px;
    margin: auto;
    margin-top: $spacing-y--md;
    margin-bottom: $spacing-y--md;

    @each $color-name, $color-value in $bol-doc-colors {
      &--#{$color-name} {
        .bol-tile {
          border-top-color: $color-value;
        }
      }
    }
  }

  @each $color-name, $color-value in $bol-doc-colors {
    &--#{$color-name} {
      border-top-color: $color-value;
    }
  }

  &__header {
    margin-bottom: auto;
    overflow: hidden;

    @media #{$small-only} {
      max-height: 100px;
    }

    @media #{$medium-up} {
      height: 140px;
    }
  }

  &__heading {
    color: $color-black;
    word-wrap: break-word;
    overflow: hidden;

        @media #{$ie-only} {
      width: 100%;
      word-wrap: break-word
    }
  }

  &__footer {
    color: $color-gray;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > .bol-badge {
    margin-right: auto; 

    + * {
      margin-top: 5px;
    }
  }

  @media #{$small-only} {
    &__heading {
      font-size: $font-size--small;
    }

    &__footer {
      font-size: $font-size--xsmall;
    }
  }

  @media #{$medium-up} {
    height: 210px;
    min-width: $min-width--tile--md;
    width: $width--tile--md;
    max-width: $width--tile--md;

    &__heading {
      font-size: $font-size--medium;
    }

    &__footer {
      font-size: $font-size--small;
    }
  }
}
