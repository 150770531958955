@import '_mixins.scss';

.spa-btn {
  background-color: $color--base;
  color: $color-on--base;
  border: $border--btn;
  cursor: pointer;
  font-family: $font--btn;
  font-weight: $font-weight--btn;
  font-size: $font-size--btn;
  line-height: $line-height--btn;
  text-align: center;
  text-decoration: none;
  position: relative;
  transition: $transition;
  min-width: 80px;

  border-radius: $border-radius--btn--medium;
  padding: $padding--btn--medium;

  > span {
    vertical-align: middle;
  }

  &:focus {
    outline: $outline;
  }

  &--large {
    @include spa-btn--large;
  }

  &--medium {
    @include spa-btn--medium;
  }

  &--small {
    @include spa-btn--small;
  }

  &--xsmall {
    @include spa-btn--xsmall;
  }

  &--primary {
    background-color: $color--primary;
    color: $color-on--primary;
    border-color: $color--primary;

    &:hover {
      background-color: $color--secondary;
      color: $color--base;
      border-color: $color--secondary;
      text-decoration: none;
    }

    &:focus,
    &:active {
      background-color: darken($color--secondary, 10%);
      color: $color-on--primary;
      border-color: darken($color--secondary, 10%);
    }
  }

  &--secondary {
    background-color: $color--base;
    color: $color--primary;
    border-color: $color--primary;

    &:hover {
      background-color: $color--secondary;
      color: $color-on--secondary;
      border-color: $color--secondary;
      text-decoration: none;
    }

    &:focus,
    &:active {
      background-color: darken($color--secondary, 10%);
      color: $color-on--secondary;
      border-color: darken($color--secondary, 10%);
    }
  }

  &--reverse {
    background-color: $color--base;
    color: $color--secondary;
    border-color: $color--base;

    &:hover {
      background-color: $color--base;
      color: darken($color--secondary, 10%);
    }

    &:focus,
    &:active {
      background-color: darken($color--base, 10%);
      color: darken($color--secondary, 10%);
      border-color: darken($color--base, 10%);
    }
  }

  &--link {
    background-color: transparent;
    color: $color--link;
    border: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-align: left;
    min-width: auto;

    &:hover {
      color: $color--link-hover;
      text-decoration: underline;
    }

    &:focus,
    &:active {
      color: $color--link-focus;
      text-decoration: none;
    }
  }  

  &--action {
    &.spa-btn--link {
      @include render-action;

      &.spa-btn--small {
        @include render-action($width: 6px);
        padding-right: ($spacing-x / 2) + 6px;
      }

      &.spa-btn--large {
        @include render-action($top: 55%);
        padding-right: ($spacing-x / 2) + 10px;
      }
    }

    &.spa-btn--primary,
    &.spa-btn--secondary,
    &.spa-btn--reverse {
      @include render-action($right: $spacing-x--btn--medium);
      padding-right: ($spacing-x / 2) + $spacing--chevron + $spacing-x--btn--medium;

      &.spa-btn--small {
        @include render-action($right: $spacing-x--btn--small, $width: 6px);
        padding-right: ($spacing-x / 2) + 6px + $spacing-x--btn--small;
      }

            &.spa-btn--large {
        @include render-action($right: $spacing-x--btn--large, $width: 10px);
        padding-right: ($spacing-x / 2) + 10px + $spacing-x--btn--large;
      }
    }
  }

  &--back {
    &.spa-btn--link {
      @include render-action($right: auto, $left: 0, $padding: 0);
      padding-left: ($spacing-x / 2) + (2 * $spacing--chevron);

      &::after {
        transform: translateY(-50%) rotate(135deg);
      }
    }

    &.spa-btn--primary,
    &.spa-btn--secondary,
    &.spa-btn--reverse {
      @include render-action($right: auto, $left: $spacing-x--btn--medium);
      padding-left: ($spacing-x / 2) + (2 * $spacing--chevron) + $spacing-x--btn--medium;
      padding-right: $spacing-x--btn--medium;

      &::after {
        transform: translateY(-50%) rotate(135deg);
      }
    }
  }

  &--toggle {
    &.spa-btn--link {
      @include render-toggle;
    }

    &.spa-btn--small {
      @include render-toggle($width: 6px);
    }

    &.spa-btn--primary,
    &.spa-btn--secondary,
    &.spa-btn--reverse {
      @include render-toggle($top: 45%, $right: $spacing-x--btn--medium);
      padding-right: ($spacing-x / 2) + (2 * $spacing--chevron) + $spacing-x--btn--medium;

      &.spa-btn--small {
        @include render-toggle($top: 42%, $right: $spacing-x--btn--small, $width: 6px);
        padding-right: ($spacing-x / 2) + (2 * 6px) + $spacing-x--btn--small;
      }
    }
  }

  &--top {
    &.spa-btn--link {
      @include render-toggle($right: auto, $left: 0, $padding: 0);
      padding-left: ($spacing-x / 2) + (2 * $spacing--chevron);

      &::after {
        transform: rotate(-135deg);
      }
    }

    &.spa-btn--primary,
    &.spa-btn--secondary,
    &.spa-btn--reverse {
      @include render-toggle($top: 45%, $right: auto, $left: $spacing-x--btn--medium, $padding: 0);
      padding-left: ($spacing-x / 2) + (2 * $spacing--chevron) + $spacing-x--btn--medium;
      padding-right: $spacing-x--btn--medium;

      &::after {
        transform: rotate(-135deg);
      }
    }
  }


























  &--action,
  &--back,
  &--toggle,
  &--top {

    &.spa-btn--primary {
      &::after {
        border-color: $color--base !important;
      }
    }

    &.spa-btn--secondary {
      &::after {
        border-color: $color--primary !important;
      }

      &:hover,
      &:focus,
      &:active {
        &::after {
          border-color: $color--base !important;
        }
      }
    }

    &.spa-btn--reverse {
      &:hover,
      &:focus,
      &:active {
        &::after {
          border-color: darken($color--secondary, 10%) !important;
        }
      }
    }
  }

  &--disabled,
  &:disabled {
    background-color: $color--disabled;
    color: $color-on--disabled;
    border-color: $color--disabled;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      background-color: $color--disabled;
      color: $color-on--disabled;
      border-color: $color--disabled;
      text-decoration: none;
    }

    &.spa-btn--reverse {
      background-color: $color--base;
      color: $color-on--disabled;
      border-color: $color--base;
    }
  }

  &--feedback {
    box-shadow: $box-shadow;
    position: fixed;
    bottom: 80px;
    right: 30px;

    @media #{$small-only} {
      display: none;
    }
  }


  @include build-responsive-button-classes--media-query-version;
}
