.bol-activity-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  @include push-down;

  &__item {
    flex-grow: 1;
    flex-basis: 0px;
    position: relative;
    margin-left: 2px;

    &:first-child {
      margin-left: 0;

      > .spa-btn {
        border-top-left-radius: $border-radius--btn;
        border-bottom-left-radius: $border-radius--btn;
        margin-left: 0;
      }
    }

    &:last-child {
      > .spa-btn {
        border-top-right-radius: $border-radius--btn;
        border-bottom-right-radius: $border-radius--btn;
      }
    }

    > .spa-btn {
      border-radius: 0;
      margin-bottom: 0;
      margin-left: 2px;
      min-width: 60px;
      width: 100%;
      height: 100%;
    }

    @media #{$medium-up} {
      flex-grow: 0;
      flex-basis: auto;
      margin-left: $spacing-x;

      > .spa-btn,
      > .spa-btn--medium {
        border-radius: $border-radius--btn--medium;
        width: auto;

        ~ .bol-popover {
          top: 48px;
        }
      }

      > .spa-btn--small {
        border-radius: $border-radius--btn--small;

        ~ .bol-popover {
          top: 42px;
        }
      }
    }

    .bol-popover {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;

      &__body {
        padding: 0;
      }

      @media #{$small-only} {
        border: none;
        border-radius: 0;
      }

      @media #{$medium-up} {
        position: absolute;
        height: auto;
        min-width: 375px;
      }
    }

    &.active {
      .bol-popover {
        display: block;
      }
    }
  }
}
