.bol-dot {
  display: inline-block;
  position: relative;
  width: 24px;
  height: $font-size--medium;

  @include render-dot;

  @each $color-name, $color-value in $contextual-colors {
    &--#{$color-name} {
      @include paint-dot($color-value);
    }
  }

  @for $i from 0 to length($bol-colors) {
    &-#{$i} {
      @extend .bol-dot;
      @include paint-dot(nth(map-values($bol-colors), $i + 1));
    }
  }
}
