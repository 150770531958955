@mixin extend-styles-for-insight($color) {
  $spacing-y--card-insight: 8px;

  $padding--card-insight: $spacing-y--card-insight $spacing-x--card;
  $padding--card-insight--md: $spacing-y--card-insight $spacing-x--card--md;
  $padding--card-insight--lg: $spacing-y--card-insight $spacing-x--card--lg;

  @extend .bol-card--bordered;

  > .bol-badge {
    background-color: $color;
    color: $color-white;
    line-height: 1;
    padding: 4px 8px;
    min-width: auto;
    width: auto; 

    @media #{$medium-up} {
      padding: 4px 12px;
    }
  }

  .bol-card__header:not(:last-child),
  .bol-card__body:not(:last-child) {
    padding: $padding--card-insight;

    @media #{$medium-up} {
      padding: $padding--card-insight--md;
    }

    @media #{$large-up} {
      padding: $padding--card-insight--lg;
    }
  }

  .bol-card__header {
    + .bol-card__header,
    + .bol-card__body,
    + .bol-card__footer {
      padding-top: 0;
      margin-top: $margin-top--card--lg;
    }
  }

  .bol-card__heading {
    font-size: $font-size--medium;
    line-height: $line-height--medium;
  }

  .bol-card__body {
    + .bol-card__body,
    + .bol-card__footer {
      padding-top: 0;
    }

    font-size: $font-size--small;
    line-height: $line-height--small;

    > p {
      color: $color-gray;
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .bol-card__cta {
    display: inline-block;
    background-color: transparent;
    color: $color--link;
    border: none;
    cursor: pointer;
    font-family: $roboto-bold-family, $roboto-font-fallbacks;
    font-weight: $font-weight--bold;
    font-size: $font-size--medium;
    line-height: $line-height--btn;
    padding: 0;
    margin: 0;
    text-decoration: none;
    text-align: left;
    min-width: auto;
    position: relative;
    transition: $transition;
    @include render-action;
    padding-right: ($spacing-x / 2) + 10px;

    &:hover {
      color: $color--link-hover;
      text-decoration: underline;
    }

    &:focus,
    &:active {
      color: $color--link-focus;
      text-decoration: none;
    }
  }
}

.bol-card {
  &--todo {
    @include extend-styles-for-insight($color--todo);

    @media #{$ie-only} {
      > .bol-badge {
        width: 57px;
      }
    }
  }

  &--education {
    @include extend-styles-for-insight($color--education);

    @media #{$ie-only} {
      > .bol-badge {
        width: 78px;
      }
    }
  }

  &--insight {
    @include extend-styles-for-insight($color--insight);

    @media #{$ie-only} {
      > .bol-badge {
        width: 60px;
      }
    }
  }

  &--next {
    .bol-card__heading {
      @extend .bol-card__heading--xlarge;
    }

    .bol-card__body {
      @extend .bol-card__body--separated;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .bol-card__footer {
      @extend .bol-card__footer--separated;
    }

    .bol-card__cta {
      display: block;

      @media #{$medium-up} {
        display: inline-block;
      }

      @extend .spa-btn;
      @extend .spa-btn--medium;
      @extend .spa-btn--primary;
    }
  }
}
