.bol-card-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include push-down;

  > .bol-card {
    flex: 1 0 auto; 
    @include push-down;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--horizontal {

    @media #{$medium-up} {
      flex-direction: row;
      flex-wrap: nowrap;

      > .bol-card {

        flex: 1;
        margin-left: $spacing-x;
        margin-bottom: 0;

        &:first-child {
          margin-left: 0;
        }
      }

      .bol-card__body + .bol-card__footer {
        margin-top: auto; 
      }
    }
  }

  &--compact {
    > .bol-card {
      border-radius: 0;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &--bordered {
        margin-bottom: -1px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
