.bol-chart {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0; 
  @include push-down;

  &--centered {
    justify-content: center;

      .bol-chart__legend {
      @media #{$medium-up} {
        width: auto;
      }
    }
  }

  &--cfg-a {

    @media #{$medium-up} {
      flex-direction: row;
    }

    .bol-chart__chart {
      + .bol-chart__legend {
        @media #{$medium-up} {
          margin-top: 0;
          margin-left: $spacing-x--md;
        }

        @media #{$large-up} {
          margin-left: $spacing-x--lg;
        }

        > .bol-chart-legend {
          @media #{$medium-up} {
            flex-direction: column;
          }
        }
      }
    }

    .bol-chart__chart--donut {
      @media #{$medium-up} {
        align-self: flex-start;
      }
    }
  }













































  &__chart {
    position: relative;
    width: 100%;

    &:focus {
      outline: $outline;
    }

    &--donut {
      align-self: center;
      max-width: 400px; 
      height: 200px; 
    }

    + .bol-chart__legend {
      margin-top: $spacing-y;
    }
  }

  &__legend {

    display: flex;
    width: 100%;
  }

  &-legend {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none; 
    margin: 0; 
    width: 100%;









    &--centered {
      justify-content: center;

      .bol-chart-legend__item {
        width: auto;
      }

      .bol-chart-legend__key,
      .bol-chart-legend__value {
        flex-basis: auto;
      }
    }

    &--cfg-a {

      @extend .bol-chart-legend--centered;

      @media #{$small-only} {
        flex-direction: column;
        align-content: center;
      }
    }

    &__item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: $spacing-y--compact $spacing-x;
      position: relative;
      width: 100%;

      > a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }

      > .bol-chart-legend .bol-chart-legend__item { 
        padding-left: 0;
        padding-right: 0;

        > a {
          padding: 0;

          &:hover {
            text-decoration: none;
          }
        }
      }

      > ul:not(.bol-chart-legend) { 
        list-style-type: disc;
        width: 100%;

        li {
          padding: $spacing-y--compact 0;
        }
      }

      &--toggle {
        border-top: $border;
        padding: 0;
        min-height: 36px;

        &:first-child {
          border-top: none;
        }

        > a {
          color: $color--body;
          padding: $spacing-y--compact 0;
          @include render-toggle($top: 16px, $color: $color--inactive);

          &:hover {
            color: $color--link-hover;
            text-decoration: none;

            &::after {
              border-color: $color--link-hover;
            }
          }
        }

        &.on {
          > a {
            &::after {
              transform: rotate(-135deg);
            }
          }
        }

        > .bol-chart-legend .bol-chart-legend__item,
        > ul li {
          padding-right: ($spacing-x / 2) + (2 * $width--chevron); 
        }
      }

      &--dot {
        padding-left: $spacing-x + $width--dot + $spacing-x !important;
        @include render-dot($left: $spacing-x + ($width--dot / 2));

        &::before {
          top: 18px; 
        }

                @for $i from 0 to length($bol-colors) {
          &-#{$i} {
            @extend .bol-chart-legend__item--dot;
            @include paint-dot(nth(map-values($bol-colors), $i + 1));
          }
        }
      }
    }

    &__key {
      @include make-flex-section(#{$flex-basis--larger});
    }

    &__value {
      @include make-flex-section(#{$flex-basis--smaller});
      margin-left: $flex-section--margin;
      text-align: right;
    }    
  }
}
