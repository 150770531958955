
$spacing-x: $grid-gutter-sm;

$spacing-x--md: $grid-gutter-md;

$spacing-x--lg: $grid-gutter-lg;

$spacing-y: 12px;

$spacing-y--md: 16px;

$spacing-y--lg: 24px;


$spacing-x--compact: $spacing-x / 2;
$spacing-y--compact: $spacing-y / 2;



$padding: $spacing-y $spacing-x;
$padding--md: $spacing-y--md $spacing-x--md;
$padding--lg: $spacing-y--lg $spacing-x--lg;



$padding--item: $spacing-y $spacing-x;
$padding--item-md: $spacing-y--md $spacing-x--md;
$padding--item-lg: $spacing-y--lg $spacing-x--lg;



$margin-bottom: $spacing-y;
$margin-bottom--md: $spacing-y--md;
$margin-bottom--lg: $spacing-y--lg;



$margin-bottom--section: $spacing-y;
$margin-bottom--section--md: $spacing-y--md;
$margin-bottom--section--lg: $spacing-y--lg;



$spacing-y--heading: 12px;
$spacing-y--heading--md: 16px;

$margin-bottom--heading: $spacing-y--heading;
$margin-bottom--heading--md: $spacing-y--heading--md;

$spacing-y--body: 12px;
$spacing-y--body--md: 16px;

$margin-bottom--body: $spacing-y--body;
$margin-bottom--body--md: $spacing-y--body--md;



$spacing--chevron: 8px; 



$spacing-x--modal: 16px;
$spacing-y--modal: 16px;
$spacing-x--modal--md: 20px;
$spacing-y--modal--md: 20px;
$spacing-x--modal--lg: 24px;
$spacing-y--modal--lg: 24px;



$spacing-x--tooltip: 12px;
$spacing-y-top--tooltip: 20px;
$spacing-y-bottom--tooltip: 12px;



$spacing-y--card: 12px;
$spacing-x--card: 12px;
$spacing-y--card--md: 16px;
$spacing-x--card--md: 16px;
$spacing-y--card--lg: 24px;
$spacing-x--card--lg: 24px;
$spacing-y--card--xl: 32px;
$spacing-x--card--xl: 32px;

$padding--card: $spacing-y--card $spacing-x--card;
$padding--card--md: $spacing-y--card--md $spacing-x--card--md;
$padding--card--lg: $spacing-y--card--lg $spacing-x--card--lg;
$padding--card--xl: $spacing-y--card--xl $spacing-x--card--xl;

$margin-top--card--lg: $spacing-y--body--md - $spacing-y--card--lg; 



$flex-section--margin: $spacing-x;







$flex-basis--larger: 65%;
$flex-basis--smaller: calc(35% - #{$flex-section--margin});
$flex-basis--half: 50%;
$flex-basis--smaller-half: calc(50% - #{$flex-section--margin});
