.bol-timeline {

  &__perforation {
    text-align: center;
    display: block;
    position: relative;
    background: repeating-linear-gradient(to right,
        $color--border,
        $color--border 5px,
        transparent 5px,
        transparent 8px);
    height: 1px;
    margin: 12px 0;

    >.bol-timeline__label {
      background: white;
      font-weight: bold;
      padding: 0 15px;
      position: relative;
      top: -12.5px;
    }
  }

  &__timepoint {
    padding-left: 20px !important;
    border-left: $border;
    position: relative;
    display: flex;
    align-items: center;

    &:first-of-type {
      padding-top: 0 !important;
      border-image: linear-gradient(transparent 0, transparent 50%, $color--border 50%) 1;
      border-top-left-radius: $border-radius;
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
    }

    &:last-of-type {
      padding-bottom: 0 !important;
      border-image: linear-gradient($color--border 0, $color--border 50%, transparent 50%) 1;
      border-bottom-left-radius: $border-radius;
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
    }

    &:only-of-type {
      border: none;
    }

    &::before {
      content: '';
      height: 11px;
      width: 11px;
      background-color: $color--border;
      border-radius: 50%;
      position: absolute;
      left: -6px;
      top: calc(50% - 6px);
    }

    &:hover {
      background-color: $color--hover;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

    >.bol-timeline__key {
      &:only-child {

        flex-basis: auto;
        width: 100%;
      }
    }

    &--toggle {
      @include render-toggle;

      &::after {
        border-color: $color-gray;
      }

      &:hover,
      &:focus {
        text-decoration: none;

        &::after {
          border-color: $color--body;
        }
      }
    }

    &--action {
      @include render-action;

      &:hover {
        text-decoration: none;
      }
    }

    &--cfg-a {


      .bol-timeline__value {
        text-align: left;
      }
    }

    &--cfg-b {


      .bol-timeline__key {
        @include make-flex-section(#{$flex-basis--half});
      }

      .bol-timeline__value {
        @include make-flex-section(#{$flex-basis--smaller-half});
      }
    }

    &--cfg-c {

      flex-direction: column;

      .bol-timeline__key {
        @include make-flex-section();
      }

      .bol-timeline__value {
        @include make-flex-section();
        text-align: left;
        margin-left: 0;
      }
    }

    &--cfg-d {


      .bol-timeline__key {
        @include make-flex-section(#{$flex-basis--smaller});
      }

      .bol-timeline__value {
        @include make-flex-section(#{$flex-basis--larger});
        text-align: left;
      }
    }

    &--cfg-e {


      .bol-timeline__key {
        @include make-flex-section(#{$flex-basis--larger});
      }

      .bol-timeline__value {
        @include make-flex-section(#{$flex-basis--smaller});
      }
    }





    &--cfg-h {


      @media #{$medium-up} {
        display: block;
        flex-direction: column;

        .bol-timeline__key {
          flex-basis: 100%;
          margin-right: 0;
        }

        .bol-timeline__value {
          flex-basis: 100%;
          text-align: left;
        }
      }
    }

  }

  &__key {
    @include make-flex-section(#{$flex-basis--larger});
  }

  &__value {
    @include make-flex-section(#{$flex-basis--smaller});
    margin-left: $flex-section--margin;
    text-align: right;




  }

}
