.bol-navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: transparent;
  border-bottom: $border;
  border-bottom: $border;
  font-family: $font--body;
  font-size: $font-size--body;
  font-weight: $font-weight--body;
  overflow-x: auto;
  width: 100%;

  &__item {
    border-bottom: 2px solid transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: top;
    text-decoration: none;
    padding: $padding--item;
    height: 36px;
    min-width: 120px;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:focus {
      outline-offset: -1px;
    }

    &--active {
      border-bottom: 2px solid $color--primary;
    }
  }

  @media #{$medium-up} {
    overflow: hidden;
  }
}
