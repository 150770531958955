



& {
  color: $color--body;
  font-family: $font--body;
  font-weight: $font-weight--body;
  font-size: $font-size--body;
  line-height: $line-height--body;
}

p {
  font-family: $font--body;
  font-weight: $font-weight--body;
  font-size: $font-size--body;
  line-height: $line-height--body;
  margin-bottom: $margin-bottom--body;

  @media #{$medium-up} {
    margin-bottom: $margin-bottom--body--md;
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: $color--headline;
  font-family: $font--headline;
  font-weight: $font-weight--headline;
  margin: 0 0 $margin-bottom--heading;

  @media #{$large-up} {
    margin-bottom: $margin-bottom--heading--md;
  }
}

h1, .h1 {
  font-size: 36px;
  line-height: 44px;
}

h2, .h2 {
  font-size: 32px;
  line-height: 36px;
}

h3, .h3 {
  font-size: 28px;
  line-height: 36px;
}

h4, .h4 {
  font-size: 24px;
  line-height: 32px;
}

h5, .h5 {
  font-size: 20px;
  line-height: 28px;
}

h6, .h6 {
  font-size: 16px;
  line-height: 24px;
}

hr {
  border-color: $color--border;
}

a {
  color: $color--link;
  text-decoration: none;

  &:hover {
    color: $color--link-hover;
    text-decoration: underline;
  }

  &:focus {
    outline: $outline;
  }

  &:focus,
  &:active {
    color: $color--link-focus;
    text-decoration: none;
  }
}

.text-heading-xlarge {
  font-family: $font--heading;
  font-weight: $font-weight--heading;
  font-size: $font-size--heading--xlarge;
  line-height: $line-height--heading--xlarge;
}

.text-heading-large {
  font-family: $font--heading;
  font-weight: $font-weight--heading;
  font-size: $font-size--heading--large;
  line-height: $line-height--heading--large;
}

.text-heading {
  font-family: $font--heading;
  font-weight: $font-weight--heading;
  font-size: $font-size--heading;
  line-height: $line-height--heading;
}

.text-disclaimer {
  color: $color--disclaimer;
  font-family: $font--disclaimer;
  font-weight: $font-weight--disclaimer;
  font-size: $font-size--disclaimer;
  line-height: $line-height--disclaimer;
}

.text-xlarge {
  font-size: $font-size--xlarge;
  line-height: $line-height--xlarge;
}

.text-large {
  font-size: $font-size--large;
  line-height: $line-height--large;
}

.text-medium {
  font-size: $font-size--medium;
  line-height: $line-height--medium;
}

.text-small {
  font-size: $font-size--small;
  line-height: $line-height--small;
}

.text-xsmall {
  font-size: $font-size--xsmall;
  line-height: $line-height--xsmall;
}

.text-black {
  color: $color-black;
}

.text-medium-gray {
  color: $color-gray;
}

.text-light-gray {
  color: $color-gray-s-70;
}

.text-white {
  color: $color-white;
}

.text-blue {
  color: $color-royal-one;
}

.text-red {
  color: $color-brand-primary;
}

.text-positive {
  color: $color--positive;
}

.text-negative {
  color: $color--negative;
}

.text-primary {
  color: $color--primary;
}
