.bol-popover {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  background-color: $color--base;
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow--dialog;
  width: 100%;

  &__close {
    @include render-close-button($top: $spacing-y, $right: $spacing-x);
  }

  &__header {
    padding: $spacing-y $spacing-x;
    padding-right: 18px + $spacing-x; 

    + .bol-popover__body {
      padding-top: 0;
    }

    @media #{$medium-up} {
      padding: $spacing-y $spacing-x--md;
      padding-right: 18px + $spacing-x--md; 
    }

    @media #{$large-up} {
      padding: $spacing-y $spacing-x--lg;
      padding-right: 18px + $spacing-x--lg; 
    }
  }

  &__heading {
    font-family: $font--tooltip--heading;
    font-weight: $font-weight--tooltip--heading;
    font-size: $font-size--tooltip--heading;
    line-height: $line-height--tooltip--heading;
    margin: 0;
  }

  &__body-wrapper {
    overflow-y: auto;
    height: 100%;
  }

  &__body {
    padding: $spacing-y $spacing-x;

    @media #{$medium-up} {
      padding: $spacing-y--md $spacing-x--md;
    }

    @media #{$large-up} {
      padding: $spacing-y--lg $spacing-x--lg;
    }

    font-size: $font-size--tooltip;
    line-height: $line-height--tooltip;

    p {
      font-size: $font-size--tooltip;
      line-height: $line-height--tooltip;
    }
  }

  @media #{$medium-up} {
    max-width: 267px;
  }
}
