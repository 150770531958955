.bol-step-wizard {
  display: flex;
  color: $color-gray;
  font-family: $font--body;
  font-weight: $font-weight--body;
  font-size: $font-size--body;
  list-style-type: none;
  padding: 20px 0 10px;
  margin: 0;
  width: 100%;

  @media #{$medium-up} {
    padding: 40px 0;
  }

    &__item {
    flex: 1;
    border-top: 8px solid $color-gray-s-10;
    padding: 0 15px 0 0;

    &:last-child {
      border-top-color: transparent;
    }

    &--completed {
      border-top-color: $color-brand-secondary;
    }

    &--completed,
    &--active {

      .bol-step-wizard__number {
        background-color: $color-brand-secondary;
        color: $color-white;
      }

      a.bol-step-wizard__number:hover {
        box-shadow: $box-shadow;
      }

      .bol-step-wizard__text {
        color: $color-brand-secondary;
      }
    }
  }

    &__number {
    border-radius: 50%;
    background-color: $color-gray-s-10;
    color: $color-black-alt;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 31px;
    text-align: center;
    margin: 0;
    margin-top: -19px;
    margin-left: -1px;
  }

    &__text {
    display: none;

    @media #{$medium-up} {
      display: flex;
      padding: 0 0 0 35px;
      margin-top: -5px;
      text-align: left;
    }
  }
}
