



@import "_colors";





@import "_fonts";




@mixin build-font($family, $weight: 300, $style: normal, $variant: normal) {
  font-family: $family;
  src: url('../font/#{$family}/#{$family}.eot');
  src: url('../font/#{$family}/#{$family}.eot?#iefix') format('embedded-opentype'),
       url('../font/#{$family}/#{$family}.woff2') format('woff2'),
       url('../font/#{$family}/#{$family}.woff') format('woff'),
       url('../font/#{$family}/#{$family}.ttf') format('truetype');
  font-weight: $weight;
  font-style: $style;
  font-variant: $variant;
}


@font-face {
  @include build-font($roboto-family, $font-weight--normal, normal);
}


@font-face {
  @include build-font($roboto-italic-family, $font-weight--normal, italic);
}


@font-face {
  @include build-font($roboto-medium-family, $font-weight--normal, normal);
}


@font-face {
  @include build-font($roboto-medium-italic-family, $font-weight--normal, italic);
}


@font-face {
  @include build-font($roboto-bold-family, $font-weight--bold, normal);
}


@font-face {
  @include build-font($roboto-bold-italic-family, $font-weight--bold, italic);
}





@import '_typography.scss';





@import '_grid.scss';





@import '_spacing.scss';





@import '_shapes.scss';





@import '_borders.scss';





@import '_shadows.scss';





$border-radius--btn--large: 28px;
$spacing-y--btn--large: 12px;
$spacing-x--btn--large: 32px;
$padding--btn--large: $spacing-y--btn--large $spacing-x--btn--large;

$border-radius--btn--medium: 22px;
$spacing-y--btn--medium: 10px;
$spacing-x--btn--medium: 24px;
$padding--btn--medium: $spacing-y--btn--medium $spacing-x--btn--medium;

$border-radius--btn--small: 16px;
$spacing-y--btn--small: 8px;
$spacing-x--btn--small: 16px;
$padding--btn--small: $spacing-y--btn--small $spacing-x--btn--small;

$border-radius--btn--xsmall: 18px;
$spacing-y--btn--xsmall: 6px;
$spacing-x--btn--xsmall: 12px;
$padding--btn--xsmall: $spacing-y--btn--xsmall $spacing-x--btn--xsmall;

$border--btn: 1px solid transparent;

$border-radius--btn: $border-radius--btn--medium;





$border-radius--badge: 4px;
$spacing-y--badge: 0px;
$spacing-x--badge: 12px;
$padding--badge: $spacing-y--badge $spacing-x--badge;






$spacing-y--input: 12px;
$spacing-x--input: 12px;

$padding--input: $spacing-y--input $spacing-x--input;

$border--input: 1px solid $color-gray-s-70;

$border-radius--input: 4px;





$transition: $spa-standard-transition;

@keyframes icon-loading-animation {
  100% {
    transform: rotate(360deg);
  }
}





$ie-only: 'only screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)';
