
$cnx-light-family: 'cnx-light';
$cnx-light-italic-family: 'cnx-light-italic';
$cnx-family: 'cnx-regular';
$cnx-italic-family: 'cnx-italic';
$cnx-medium-family: 'cnx-medium';
$cnx-medium-italic-family: 'cnx-medium-italic';
$cnx-bold-family: 'cnx-bold';
$cnx-bold-italic-family: 'cnx-bold-italic';
$cnx-cond-family: 'cnx-regular-cond';
$cnx-italic-cond-family: 'cnx-italic-cond';
$cnx-font-fallbacks: Arial, Helvetica, sans-serif;


$roboto-family: 'roboto-regular';
$roboto-italic-family: 'roboto-italic';
$roboto-medium-family: 'roboto-medium';
$roboto-medium-italic-family: 'roboto-medium-italic';
$roboto-bold-family: 'roboto-bold';
$roboto-bold-italic-family: 'roboto-bold-italic';
$roboto-font-fallbacks: Arial, Helvetica, sans-serif;


$font-weight--normal: 400;
$font-weight--medium: 500;
$font-weight--bold: 700;


$font--headline: $cnx-family, $cnx-font-fallbacks;
$font-weight--headline: $font-weight--normal;


$font--heading: $cnx-bold-family, $cnx-font-fallbacks;
$font-weight--heading: $font-weight--bold;


$font--subheading: $roboto-medium-family, $roboto-font-fallbacks;
$font-weight--subheading: $font-weight--medium;


$font--body: $roboto-family, $roboto-font-fallbacks;
$font-weight--body: $font-weight--normal;


$font--disclaimer: $roboto-family, $roboto-font-fallbacks;
$font-weight--disclaimer: $font-weight--normal;


$font--modal--heading: $cnx-bold-family, $cnx-font-fallbacks;
$font-weight--modal--heading: $font-weight--bold;


$font--modal: $roboto-family, $roboto-font-fallbacks;
$font-weight--modal: $font-weight--normal;


$font--tooltip--heading: $roboto-bold-family, $roboto-font-fallbacks;
$font-weight--tooltip--heading: $font-weight--bold;


$font--btn: $roboto-medium-family, $roboto-font-fallbacks;
$font-weight--btn: $font-weight--medium;
