.bol-alert {
  background-color: $color--base;
  border: 1px solid transparent;
  position: relative;
  margin: $spacing-y 0;
  padding: 0;

  @media #{$ie-only} {

      padding: 1px;
  }

  @media #{$medium-up} {
    margin: $spacing-y--md 0;
  }

  @media #{$large-up} {
    margin: $spacing-y--lg 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &__header,
  &__body {

    background-color: $color--base;
    margin-left: 40px;
    padding: $padding;

    @media #{$medium-up} {
      margin-left: 60px;
    }
  }

  &__header {
    > .bol-alert__heading {
      margin: 0 !important;
    }

    + .bol-alert__body {
      padding-top: 0;
      min-height: 0;
    }
  }

  &__heading {
    font-family: $font--heading;
    font-weight: $font-weight--heading;
    font-size: $font-size--heading !important;
    line-height: inherit;

    @media #{$large-up} {
      font-size: $font-size--heading--large !important;
      line-height: inherit;
    }
  }

  &__body {
    font-size: $font-size--small;
    line-height: $line-height--small;
    min-height: 40px;

    @media #{$medium-up} {
      font-size: $font-size--medium;
      line-height: $line-height--medium;
      min-height: 60px;
    }

    p {
      font-size: inherit;
      line-height: inherit;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &::before {
    display: inline-block;
    position: absolute;
    content: '';
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;

    @media #{$medium-up} {
      width: 40px;
      height: 40px;
    }
  }

  &--error {
    background-color: #f9dee1;
    border-color: #dc1431;

    &.bol-alert--inline,
    .bol-alert__heading {
      color: $color--error;
    }

    &::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PHBhdGggZmlsbD0iI0Q0MDAxNCIgZD0iTTQuNiAzOS41Yy0xLjUgMC0yLjgtLjctMy41LTEuOS0uNy0xLjItLjgtMi43LS4xLTRMMTYuNCAyLjhDMTcuMSAxLjQgMTguNS41IDIwIC41czIuOS45IDMuNiAyLjNMMzkgMzMuNWMuNyAxLjMuNiAyLjgtLjEgNC0uOCAxLjItMi4xIDEuOS0zLjUgMS45SDQuNnoiLz48Y2lyY2xlIGZpbGw9IiNGRkYiIGN4PSIyMCIgY3k9IjMyIiByPSI0Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTIwIDljLTEuNyAwLTMgMS4zLTMgM3YxMWMwIDEuNyAxLjMgMyAzIDNzMy0xLjMgMy0zVjEyYzAtMS43LTEuMy0zLTMtM3oiLz48L3N2Zz4=);
    }
  }

  &--success {
    background-color: #eef6e6;
    border-color: #7bb63f;

    &::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PHBhdGggZmlsbD0iIzZDQzI0QSIgZD0iTTIwIDM5LjVDOS4zIDM5LjUuNSAzMC44LjUgMjBTOS4zLjUgMjAgLjUgMzkuNSA5LjMgMzkuNSAyMCAzMC44IDM5LjUgMjAgMzkuNXoiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMzEuOSAxMC43Yy0xLjMtMS0zLjEtLjgtNC4yLjVsLTkuOSAxMi42LTctNS42Yy0xLjMtMS0zLjEtLjgtNC4yLjUtMSAxLjMtLjggMy4yLjUgNC4ybDkuMyA3LjVjMS4zIDEgMy4xLjggNC4yLS41bDExLjgtMTVjMS0xLjMuOC0zLjItLjUtNC4yeiIvPjwvc3ZnPg==);
    }
  }

  &--info {
    background-color: #deeef7;
    border-color: #007dc3;

    &::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PHBhdGggZmlsbD0iIzAwNzNDRiIgZD0iTTIwIDM5LjVDOS4zIDM5LjUuNSAzMC44LjUgMjBTOS4zLjUgMjAgLjUgMzkuNSA5LjMgMzkuNSAyMCAzMC44IDM5LjUgMjAgMzkuNXoiLz48Y2lyY2xlIGZpbGw9IiNGRkYiIGN4PSIyMCIgY3k9IjExIiByPSI0Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTIwIDM0YzEuNyAwIDMtMS4zIDMtM1YyMGMwLTEuNy0xLjMtMy0zLTNzLTMgMS4zLTMgM3YxMWMwIDEuNyAxLjMgMyAzIDN6Ii8+PC9zdmc+);
    }
  }

  &--warning {
    background-color: #fcf0e6;
    border-color: #eb8b3e;

    &::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PHBhdGggZmlsbD0iI0VBNzYwMCIgZD0iTTQuNiAzOS41Yy0xLjUgMC0yLjgtLjctMy41LTEuOS0uNy0xLjItLjgtMi43LS4xLTRMMTYuNCAyLjhDMTcuMSAxLjQgMTguNS41IDIwIC41czIuOS45IDMuNiAyLjNMMzkgMzMuNWMuNyAxLjMuNiAyLjgtLjEgNC0uOCAxLjItMi4xIDEuOS0zLjUgMS45SDQuNnoiLz48Y2lyY2xlIGZpbGw9IiNGRkYiIGN4PSIyMCIgY3k9IjMyIiByPSI0Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTIwIDljLTEuNyAwLTMgMS4zLTMgM3YxMWMwIDEuNyAxLjMgMyAzIDNzMy0xLjMgMy0zVjEyYzAtMS43LTEuMy0zLTMtM3oiLz48L3N2Zz4=);
    }
  }

  &--inline {
    background-color: transparent;
    border: none;
    margin: 0;
    margin-top: $spacing-y;
    padding-left: 30px;
    min-height: 21px;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
    }
  }

  &.bol-alert-sm {

    margin: $spacing-y 0;

    .bol-alert__body {
      margin-left: 40px;
      min-height: 40px;
    }

    &::before {
      width: 20px;
      height: 20px;
    }
  }
}
