@mixin spa-btn--block {
  display: block;
  width: 100%;
}

@mixin spa-btn--large {
  font-size: $font-size--large;
  border-radius: $border-radius--btn--large;
  padding: $padding--btn--large;
}

@mixin spa-btn--medium {
  font-size: $font-size--medium;
  border-radius: $border-radius--btn--medium;
  padding: $padding--btn--medium;
}

@mixin spa-btn--small {
  font-size: $font-size--small;
  border-radius: $border-radius--btn--small;
  padding: $padding--btn--small;
}

@mixin spa-btn--xsmall {
  font-size: $font-size--xsmall;
  border-radius: $border-radius--btn--xsmall;
  padding: $padding--btn--xsmall;
}

@mixin build-responsive-button-classes-for-breakpoint($breakpoint-name) {
  @each $modifier in (small, medium, large, block) {
    &--#{$modifier}-at-#{$breakpoint-name} {
      @if $modifier == small {
        @include spa-btn--small;
      } @else if $modifier == medium {
        @include spa-btn--medium;
      } @else if $modifier == large {
        @include spa-btn--large;
      } @else if $modifier == block {
        @include spa-btn--block;
      }
    }
  }
}

@mixin build-responsive-button-classes--media-query-version {
  @each $breakpoint-name, $breakpoint-query in $breakpoint-list {
    @media #{$breakpoint-query} {
      @include build-responsive-button-classes-for-breakpoint($breakpoint-name);
    }
  }
}
